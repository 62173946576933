
export const setSelectedDataMethod = (name, properties) => {
  return {
    type: 'SET_SELECTED_DATA_METHOD',
    payload: {
      name,
      properties
    }
  };
};

export const updatePropertyMethod = (selectedOption, propName, propValue) => {
  return {
    type: 'UPDATE_PROPERTY_METHOD',
    payload: {
      selectedOption,
      propName,
      propValue
    }
  };
}; 

export const resetMethod = {
  type: 'RESET_METHOD',
};