import { List, Map } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../main/utils/i18nConfigProvider';

import { setInitialForms } from '../actions/formsDataActions';
import { setSelectedDataMethod } from '../actions/methodActions';
import { setSelectedDataDecoder } from '../actions/decoderActions';
import { setTaskName } from '../actions/generalReducer';
import { setSelectedDataApp } from '../actions/applicationAction';
import { setSelectedDataExecution, setInitialExecutionQCGData } from '../actions/executionActions';
import { getStepFormData, setExecutionAdvancedParallelPropsWithUploadData } from './jsonSchemaParser';
import schemaJSON_QCG from '../../assets/config/schema/qcg_muqsa_json_schema.json';
import { convertBase64ToString } from './formUtils';
import { setInitialAdvancedParallelProps } from '../actions/executionActions';
import * as notify from '../../main/utils/notify';

const convertDataToProps = (uploadData, stepName, jsonSchema) => {
  let propsList = List();
  let singleProp, jsonSchemaProperties, uploadPropValue, tmp;

  const elemTypeProps = jsonSchema[uploadData[stepName+"_type"]].properties;

  Object.entries(elemTypeProps).forEach(schemaProp => {
    jsonSchemaProperties = schemaProp[1];
    uploadPropValue = uploadData[schemaProp[0]] || jsonSchemaProperties.default;
    
    singleProp = {};
    singleProp.name = schemaProp[0];
    singleProp.validInfo = {};
    singleProp.validInfo.type = jsonSchemaProperties.enum ? "select" : jsonSchemaProperties.type;
    if (schemaProp[1].pattern) {
      singleProp.validInfo.pattern = schemaProp[1].pattern;
    }
    if (jsonSchemaProperties.exclusiveMinimum) {
      singleProp.validInfo.min = jsonSchemaProperties.exclusiveMinimum + 1;
    }
    if (jsonSchemaProperties.anyOf) {
      singleProp.validInfo.anyOf = jsonSchemaProperties.anyOf;
    }
    if (jsonSchemaProperties.items) {
      singleProp.validInfo.items = jsonSchemaProperties.items;
    } 
    if (jsonSchemaProperties.discriminator?.propertyName === "resource_type") {
      singleProp.validInfo.type = "fileModal";
      if (uploadPropValue.resource_type === "content") {
        uploadPropValue.content = convertBase64ToString(uploadPropValue.content);
      }
    }

    if ([ "boolean", "select" ].includes(singleProp.validInfo.type)) {
      singleProp.value = {
        "label": uploadPropValue+"",
        "value": uploadPropValue
      };
    }
    else if (singleProp.validInfo.type === "array" || singleProp.validInfo.anyOf) {
      tmp = JSON.stringify(uploadPropValue).replace(/"/g, '');
      singleProp.value = tmp.substring(1, tmp.length-1);
    }
    else {
      singleProp.value = uploadPropValue;
    }      
    propsList = propsList.push(Map(singleProp));
  });

  return propsList;
};

export const convertUploadFileQcgData = (uploadFileData) => {
  let schemaObj = JSON.parse(JSON.stringify(schemaJSON_QCG));
  let listProps = List();
  let singleProp, locValue;

  let schemaProps = schemaObj?.definitions.QCGmUQSA.properties;
  Object.entries(schemaProps).forEach(property => {
    if (property[0].startsWith("execution_")) {
      singleProp = {};
      singleProp.name = property[0];
      singleProp.title = property[1].title;
      singleProp.placeholder = property[1].placeholder;
      singleProp.step = property[1].step ?? "";
      singleProp.validInfo = {};
      singleProp.validInfo.type = property[1].type;
      singleProp.validInfo.required = property[1].required ?? false;

      locValue = uploadFileData[property[0]] ?? property[1].default ?? "";
      if (singleProp.validInfo.type === "fileModal") {
        if (locValue.resource_type === "content") {
          locValue.content = convertBase64ToString(locValue.content);
        }
      } 

      singleProp.value = locValue;
      
      if ("exclusiveMinimum" in property[1]) {
        singleProp.validInfo.min = property[1].exclusiveMinimum + 1;
      }
      listProps = listProps.push(Map(singleProp));
    }
  });
  return listProps;  
};

// Adding missing values from defaultFormData
const prepareFormsData = (formData, defaultFormData, stepName) => {
  // Map through all forms  
  return formData.map((form) => {

    const tempResult = {
      ...structuredClone(defaultFormData),
      id: uuidv4(),
    };

    if (stepName === "SampledParams") {
      // Set visibility
      tempResult.isVisible = form.enabled;
      // Map through all keys in form
      Object.keys(form).forEach((key) => {
        if (key === "enabled") {
          return;
        }
        // Update input to result
        tempResult.inputsData = tempResult.inputsData.map((item) => {
          if (item.name === key) {
            if (item.fieldType === "complex") {

              // Encoder ma option nie options trzeba osobno stworzyć dla niego zachowanie
              let additionalValues = {};

              const optionIndex = item.options.findIndex((option) => {
                if (key === "sampling_space") {
                  return option.name === form[key].param_type;
                }
                return option.name === form[key].dist_type;
              });

              // Add wich option is selected
              additionalValues.value = optionIndex;
              // Add options
              additionalValues.options = item.options.map((option, index) => {
                if (index === optionIndex) {
                  return ({
                    ...option,
                    properties: option.properties.map((property) => {
                      const propertyValue = form[key][property.name];
                      const propertyDisabled = form[key]["disabled_"+property.name];
                      const tempValue = {};
                      if (propertyValue) tempValue.value = propertyValue;
                      if (propertyDisabled !== undefined) tempValue.disabled = propertyDisabled;
                      return ({
                        ...property,
                        ...tempValue
                      });
                    }),
                  });
                }
                return option;
              });

              return ({
                ...item,
                ...additionalValues,
              });
            }
            // If basic type
            return ({
              ...item,
              value: form[key],
            });
          }
          return item;
        });
      });
    } 
    else if (stepName === "Encoder") {
      let inputData = tempResult.inputsData[0];

      const optionIndex = inputData.options.findIndex(
        (option) => option.name === form.encoder_type);

      inputData.value = optionIndex;

      inputData.options[optionIndex].properties = inputData.options[optionIndex].properties.map((property) => {
        return ({
          ...property,
          value: form[property.name]
        });
      });
    }

    return tempResult;
  });
};

export const uploadDataJsonFile = (fileContent, dispatch, defaultFormsData) => {
  let jsonData;
  
  try {
    jsonData = JSON.parse(fileContent);
  }
  catch (e) {
    notify.error(i18n.t("start_page.task_modal.upload_file_error"));
    notify.error(i18n.t('validation.msg.invalid_json')+e.message);
    return null;
  }
  
  let propsList;

  //qcgparams
  dispatch(setInitialExecutionQCGData(convertUploadFileQcgData(jsonData)));

  const muqsaData = jsonData.input_muqsa_config;

  if (!muqsaData) {
    return null;
  }

  dispatch(setTaskName(muqsaData.name));

  if (muqsaData.sampled_params) {
    const preparedData = prepareFormsData(muqsaData.sampled_params, defaultFormsData[0][0], "SampledParams");
    dispatch(setInitialForms(0, preparedData));
  }

  if (muqsaData.method) {
    propsList = convertDataToProps(muqsaData.method, "method", getStepFormData("Method"));
    dispatch(setSelectedDataMethod(muqsaData.method.method_type, propsList));
  }

  if (muqsaData.encoder) {
    const preparedData = prepareFormsData(muqsaData.encoder, defaultFormsData[2][0], "Encoder");
    dispatch(setInitialForms(2, preparedData));
  }

  if (muqsaData.decoder) {
    propsList = convertDataToProps(muqsaData.decoder, "decoder", getStepFormData("Decoder"));
    dispatch(setSelectedDataDecoder(muqsaData.decoder.decoder_type, propsList));
  }

  if (muqsaData.application) {
    propsList = convertDataToProps(muqsaData.application, "application", getStepFormData("Application"));
    dispatch(setSelectedDataApp(muqsaData.application.application_type, propsList));
  }

  if (muqsaData.execution) {
    propsList = convertDataToProps(muqsaData.execution, "execution", getStepFormData("Execution"));
    dispatch(setInitialAdvancedParallelProps(setExecutionAdvancedParallelPropsWithUploadData(muqsaData.execution)));
    dispatch(setSelectedDataExecution(muqsaData.execution.execution_type, propsList));
  }
  return muqsaData.name;
};