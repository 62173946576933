import React from 'react';
import PropTypes from 'prop-types';

import { ButtonALinkStyle } from '../styles/styled-components/Button';
import IconDocumentation from '../../assets/svg/icons/documentation.svg';

const ButtonIconTypes = {
  documentation: { icon: IconDocumentation }
};

const ButtonALink = ({ className, href, icon, isBlank=true, text }) => {
  return (
    <ButtonALinkStyle 
      className={className || ""} 
      href={href} 
      target={isBlank ? "_blank" : ""} 
    >
      { text }
      { icon && <img 
        alt=""
        className="icon ml-2" 
        src={ButtonIconTypes[icon].icon} 
      />
      }
    </ButtonALinkStyle>
  );
};

ButtonALink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.oneOf([ "documentation" ]), 
  isBlank: PropTypes.bool, 
  text: PropTypes.string
};

export default ButtonALink;