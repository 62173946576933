import { Map } from 'immutable';

const initialState = Map({
  taskName: "",
  linkDoc: ""
});

export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_TASK_NAME':
    return state.set("taskName", action.payload);
  
  case 'SET_LINK_DOC': 
    return state.set("linkDoc", action.payload);

  case 'RESET_TASK_NAME': 
    return state.set("taskName", "");
  default:
    return state;
  };  
};