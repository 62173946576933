import styled from 'styled-components';

export const LabelStyle = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  color: ${props => props.theme.colors.gray.type_700};
  margin-bottom: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center; 
`;

export const RadioStyleBase = styled.input`
  margin: 0;
  width: 18px;
  height: 18px;
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  border-radius: 100px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 0px 1px ${props => props.theme.colors.pink.type_500};, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    outline: none;
  }
  :focus, :checked:focus {
    box-shadow: 0px 0px 0px 4px rgba(219, 90, 154, 0.40), 0px 0px 0px 1px #DB5A9A, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    outline: none;
  }

  :checked {
    background-color: white;
    border: 5px solid ${props => props.theme.colors.pink.type_500};
    box-shadow: 0px 0px 0px 1px ${props => props.theme.colors.pink.type_500};;

    :hover {
      border: 5px solid ${props => props.theme.colors.pink.type_600};
    }
  }
`;