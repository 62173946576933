import styled from 'styled-components';

export const AddNewFormButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  margin-top: 24px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10); 
`;