import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

const theme = {
  colors: {
    font: {
      gray: '#495057',
      red: '#D20724'
    },
    gray: {
      type_000: '#f7f9fc',
      type_050: '#e9edf5',
      type_100: '#d5dbe5',
      type_200: '#bcc2ce',
      type_300: '#a1a9b8',
      type_400: '#868fa0',
      type_500: '#687182',
      type_600: '#5a6376',
      type_700: '#464f60',
      type_800: '#333b4a',
      type_900: '#171c26'
    },
    pink: {
      type_000: '#fdebf4',
      type_050: '#fccfe5',
      type_100: '#fabbdb',
      type_200: '#f99fcc',
      type_300: '#fa89c2',
      type_400: '#f24099',
      type_500: '#e0197d',
      type_600: '#BF0D66',
      type_700: '#8f0a4d',
      type_800: '#780740',
      type_900: '#48162f',
      shadow: '#cc669980'
    },
    white: '#ffffff',
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',// 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    xl2: '1.5rem', //24px
    xl3: '1.875rem',
    xl4: '2rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.any.isRequired
};

export default Theme;