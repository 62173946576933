//#!/usr/bin/env ts-node

export class SampleEstimationError extends Error {
  constructor(msg?: string) {
    let text: string = msg === undefined ? "Error occured while estimating the number of samples" : msg;
    super(text);
  }
}

/**
 * Calculates the factorial of a number.
 * @param n - The number for which factorial needs to be calculated.
 * @returns The factorial value.
 */
function factorial(n: number): number {
  let value = 1;
  for (let i = 2; i <= n; i++) {
    value *= i;
  }
  return value;
}


/**
 * Represents the number of samples and time information.
 */
interface WalltimeResult {
  n_samples: number;
  time: number;
}

/**
 * Represents different time calculations for specific methods.
 */
interface Walltime {
  /**
   * Polynomail Chaos Expansion (PCE) time estimation.
   * @param n_params - Number of parameters.
   * @param polynomial - Polynomial value.
   * @param minutes_per_sample - Minutes per sample.
   * @param sparse - Determines if the algorithm uses sparse computation (default: false).
   * @param regression - Determines if regression is used (default: false).
   * @returns Object containing the number of samples and estimated time in minutes.
   */
  estimatePceTime: (n_params: number, polynomial: number, minutes_per_sample: number, sparse?: boolean, regression?: boolean) => WalltimeResult;

  /**
   * Stochastic Collocation (SC) time estimation.
   * @param n_params - Number of parameters.
   * @param polynomial - Polynomial value.
   * @param minutes_per_sample - Minutes per sample.
   * @param sparse - Determines if the algorithm uses sparse computation (default: false).
   * @returns Object containing the number of samples and estimated time in minutes.
   */
  estimateScTime: (n_params: number, polynomial: number, minutes_per_sample: number, sparse?: boolean) => WalltimeResult;

  /**
   * Monte Carlo (MC) time estimation.
   * @param n_params - Number of parameters.
   * @param n_samples - Number of samples.
   * @param minutes_per_sample - Minutes per sample.
   * @param quasi - Determines if quasi-random sampling is used (default: true).
   * @returns Object containing the number of samples and estimated time in minutes.
   */
  estimateMcTime: (n_params: number, n_samples: number, minutes_per_sample: number, quasi?: boolean) => WalltimeResult;

  /**
   * Base time calculation (not implemented).
   * @param n_params - Number of parameters.
   * @returns Error indicating function not implemented.
   */
  estimateBasicTime: (n_params: number) => WalltimeResult;

  /**
   * Sweep time calculation (not implemented).
   * @param n_params - Number of parameters.
   * @returns Error indicating function not implemented.
   */
  estimateSweepTime: (n_params: number) => WalltimeResult;
}

const WalltimeUtils: Walltime = {
  estimatePceTime: function (n_params: number, polynomial: number, minutes_per_sample: number, sparse: boolean = false, regression: boolean = false):
    WalltimeResult {
    let n_samples: number;
    let time: number;

    if (regression) {
      n_samples = 2 * factorial(polynomial + n_params) / (factorial(polynomial) * factorial(n_params));
    }
    else if (sparse) {
      throw new SampleEstimationError("Sparse method not implemented.");
    }
    else {
      n_samples = (polynomial + 1) ** n_params;
    }

    time = n_samples * minutes_per_sample * 1.5;

    return { n_samples, time };
  },

  estimateScTime: function (n_params: number, polynomial: number, minutes_per_sample: number, sparse: boolean = false): WalltimeResult {
    let n_samples: number;
    let time: number;

    if (sparse) {
      throw new SampleEstimationError("Sparse not implemented.");
    }
    else {
      n_samples = (polynomial + 1) ** n_params;
    }

    time = n_samples * minutes_per_sample * 1.5;

    return { n_samples, time };
  },

  estimateMcTime: function (n_params: number, n_samples: number, minutes_per_sample: number, quasi: boolean = true): WalltimeResult {
    let total_samples: number;
    let time: number;

    if (quasi) {
      total_samples = (n_params + 2) * n_samples;
    }
    else {
      total_samples = n_samples * (n_params + 2);
    }

    time = total_samples * minutes_per_sample * 1.5;

    return { n_samples: total_samples, time };
  },

  estimateBasicTime: function (n_params: number): WalltimeResult {
    throw new SampleEstimationError("Function not implemented.");
  },
  estimateSweepTime: function (n_params: number): WalltimeResult {
    throw new SampleEstimationError("Function not implemented.");
  }
};

export default WalltimeUtils;