import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as notify from '../../main/utils/notify';

import { ReactComponent as FLAG_EU } from '../../assets/svg/icons/flags/flag-eu.svg';
import { ReactComponent as FLAG_EU_ENG } from '../../assets/svg/icons/flags/flag-eu_eng.svg';
import { ReactComponent as FLAG_FE } from '../../assets/svg/icons/flags/flag-fe.svg';
import { ReactComponent as FLAG_FE_ENG } from '../../assets/svg/icons/flags/flag-fe_eng.svg';
import { ReactComponent as FLAG_RP } from '../../assets/svg/icons/flags/flag-rp.svg';
import { ReactComponent as FLAG_RP_ENG } from '../../assets/svg/icons/flags/flag-rp_eng.svg';

import ButtonText from '../components/ButtonText';
import { setStart } from '../actions/stepsActions';
import useApiFormData from '../../uqsa-ui/hooks/useApiFormData';
import { CenterDiv, ContentContainer, HomeMainDiv, FooterDiv, ImgDiv } from '../styles/styled-components/Home';
import { Para14SemiBold600, Text14Normal600, TitleH1 } from '../styles/styled-components/GlobalFonts';
import Image_home from '../../assets/img/home_image.png';
import Logo_typo from '../../assets/svg/icons/logo/logo_and_typo.svg';
import { setTaskName, resetTaskName } from '../actions/generalReducer';
import { resetMethod } from '../actions/methodActions';
import { resetDecoder } from '../actions/decoderActions';
import { resetApplication } from '../actions/applicationAction';
import { resetExecution, updatePropertyExecutionQcgDataMaxValue } from '../actions/executionActions';
import { uploadDataJsonFile } from '../utils/uploadDataFile';
import LoadingFullscreen from '../components/LoadingFullscreen';
import { initSchemaData } from '../utils/initSchemaData';
import { initGeneralDataFromJsonConfig } from '../utils/jsonSchemaParser';
import TaskNameChanger from '../components/TaskNameChanger';
import { resetFormDataParametersEncoder } from '../utils/resetFormData';
import i18n from '../../main/utils/i18nConfigProvider';
// import * as libQCG from '../lib/qcgFormToolbox';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRefUploadTask = useRef(null);
  const taskCreateMode = useRef();
  const linkDocumentation = useRef();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isShowModal, setIsShowModal ] = useState(false);
  const [ taskNameLoc, setTaskNameLoc ] = useState();
  const taskName = useSelector((state) => state.general.get("taskName"));
  const stepsData = useSelector((state) => state.steps.get('stepsData'));
  const lngCode = i18n.language.substring(0, 2).toLowerCase();
  
  useApiFormData();
  
  useEffect(() => {  
    linkDocumentation.current = initGeneralDataFromJsonConfig(dispatch) + "start/introduction/";
  }, [ dispatch ]);

  const handleClickBtnUploadTask = () => {
    inputRefUploadTask.current.click();
  };

  const basicFormsData = useSelector((state) => state.formsData.toJS());

  const handleInputUploadDataFile = event => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0]);
    fileReader.onload = e => {
      setIsLoading(true);
      const result = uploadDataJsonFile(e.target.result, dispatch, basicFormsData, setTaskNameLoc);
      if (!result) {
        setIsLoading(false);
        return;
      }
      setTaskNameLoc(result);
      notify.success(t("start_page.task_modal.upload_file_success"));
      taskCreateMode.current = "upload";
      setIsLoading(false);
      setIsShowModal(true);  
    };
    event.target.value="";
  };

  const handleClickBtnAssignTask = () => {
    taskCreateMode.current = "new";
    dispatch(resetTaskName);
    dispatch(resetMethod);
    dispatch(resetDecoder);
    dispatch(resetApplication);
    dispatch(resetExecution);
    setTaskNameLoc("");
    // setIsInvalidTaskName(false);
    if (taskName) {
      //RESET PARAMETERS AND ENCODER
      resetFormDataParametersEncoder(dispatch, stepsData);
    }
    setIsShowModal(true);
  };
  
  // const getTemplateData = async () => {
  //   if (window.location?.ancestorOrigins?.length > 0) {
  //     const templateData = await libQCG.getTemplate();
  //     //eslint-disable-next-line no-console
  //     console.log(templateData);
  //     const schemaProps = templateData.schema.properties;
  //     dispatch(updatePropertyExecutionQcgDataMaxValue("execution_cores_per_node", schemaProps.num_all_cores_in_node.default));
  //     dispatch(updatePropertyExecutionQcgDataMaxValue("execution_nodes", schemaProps.num_all_nodes.default));
  //   }
  // };
  
  const handleConfirmTaskName = async (taskName) => {
    dispatch(setTaskName(taskName));
    
    if (taskCreateMode.current === "new") {
      initSchemaData(dispatch);
    }
    
    //TODO restore "getTemplateData()" when production will be ready
    //await getTemplateData();
    dispatch(updatePropertyExecutionQcgDataMaxValue("execution_cores_per_node", 48));
    dispatch(updatePropertyExecutionQcgDataMaxValue("execution_nodes", 40));
    
    dispatch(setStart);
    
    history.push({ pathname: '/parameters' });
  };

  return (
    <> 
      { isLoading && <LoadingFullscreen /> }
      <HomeMainDiv>
        <ContentContainer>
          <div>
            <img alt={t('start_page.alt_logo')} src={Logo_typo}/>
          </div>
          <CenterDiv>
            <div>
              <TitleH1>{t('start_page.title')}</TitleH1>
              <div className='my-5 d-flex'>
                <div className="mr-3">
                  <input
                    accept="application/json"
                    type="file"
                    id="file-input-upload-task"
                    hidden
                    ref={inputRefUploadTask}
                    onChange={handleInputUploadDataFile}
                  />
                  <ButtonText
                    isKindWhite={true}
                    text={t('start_page.upload_task')}
                    onClick={handleClickBtnUploadTask}
                  />
                </div>
                <ButtonText
                  isKindWhite={false}
                  text={t('start_page.assign_task')}
                  onClick={handleClickBtnAssignTask}
                />
              </div>
              {linkDocumentation.current
                  && <>
                    <Para14SemiBold600>{t('common.documentation')}</Para14SemiBold600>
                    <a href={linkDocumentation.current} target="_blank" rel="noreferrer">
                      <Text14Normal600>{linkDocumentation.current}</Text14Normal600>
                    </a>
                  </>
              }
            </div>
            <ImgDiv>
              <img alt={t('start_page.alt_image_text')} src={Image_home}/>
            </ImgDiv>
          </CenterDiv>
          <FooterDiv className="mt-5 w-100">
            <div className="d-flex justify-content-between">
              {lngCode === 'pl' ?
                <>
                  <FLAG_FE/>
                  <FLAG_RP/>
                  <FLAG_EU/>
                </>
                :
                <>
                  <FLAG_FE_ENG/>
                  <FLAG_RP_ENG/>
                  <FLAG_EU_ENG/>
                </>
              }
            </div>
          </FooterDiv>
        </ContentContainer>
        {isShowModal
          && <TaskNameChanger modalTitle={'title_new_task_name'}
            isShowModal={isShowModal} setIsShowModal={setIsShowModal} taskName={taskNameLoc}
            taskCreateMode={taskCreateMode.current} handleConfirmTaskNameParam={handleConfirmTaskName}/>
        }
      </HomeMainDiv>
    </>
  );
};

export default Home;
