
import styled from 'styled-components';
import { ReactComponent as IconEditSrc } from '../../../assets/svg/icons/common/edit.svg';

export const IconEdit = styled(IconEditSrc)`
  height: 16px;
  width: 16px;
  color: ${props => props.theme.colors.gray.type_600};
  
  &:hover {
    color: ${props => props.theme.colors.pink.type_600};
  }
`;
