import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch; 
  width: 100%;
  margin-top: 16px; 
`;

export const FormCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(70, 79, 96, 0.16);
  background: ${({ theme, isVisible }) => isVisible ? theme.colors.white : theme.colors.gray.type_000};
`;

export const FormComplexInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const CardActionsContainer = styled.div`
  display: flex;
  height: ${({ withoutEye }) => withoutEye ? '130px' : '178px'};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CardActionButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background: ${({ theme, disabled }) => disabled ? theme.colors.gray.type_000 : theme.colors.white};
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.20);
  border: none;
`;