import React from 'react';
import PropTypes from 'prop-types';
import { ModalStyle, ModalBodyStyle, ModalHeaderStyle, ModalFooterStyle } from '../styles/styled-components/CustomModal';
import { Para12, Para14, TitleH2 } from '../styles/styled-components/GlobalFonts';
import ButtonText from './ButtonText';

const CustomModal = ({ additionalInfo, btnCancelText, btnConfirmText, children, showModal, subTitle, title, onClickCancelModal, onClickConfirmModal }) => {
  
  return (
    <ModalStyle centered isOpen={showModal} autoFocus={false}>
      <ModalBodyStyle>        
        <ModalHeaderStyle>
          <TitleH2>{title}</TitleH2>
          {subTitle && <Para14 className="mt-3">{subTitle}</Para14>}
        </ModalHeaderStyle>
        {children}
        {additionalInfo && <Para12 className="mt-3">{additionalInfo}</Para12>}
      </ModalBodyStyle>
      <ModalFooterStyle>
        <ButtonText 
          isKindWhite={true} 
          text={btnCancelText}
          onClick={onClickCancelModal}
        />
        <ButtonText 
          isKindWhite={false} 
          text={btnConfirmText}
          onClick={() => onClickConfirmModal()}
        />
      </ModalFooterStyle>
    </ModalStyle>
  );
};

CustomModal.propTypes = {
  additionalInfo: PropTypes.string, 
  btnCancelText: PropTypes.string, 
  btnConfirmText: PropTypes.string,
  children: PropTypes.any, 
  showModal: PropTypes.bool, 
  subTitle: PropTypes.string,
  title: PropTypes.string,
  onClickCancelModal: PropTypes.func, 
  onClickConfirmModal: PropTypes.func
};

export default CustomModal;