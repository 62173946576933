//add step default form for step parameters and encoder
import { v4 as uuidv4 } from 'uuid';
import { getStepFormData } from './jsonSchemaParser';
import { setInitialForms } from '../actions/formsDataActions';

export const resetFormDataParametersEncoder = (dispatch, stepsData, step=-1) => {
  
  const prepareData = (item, idx) => {
    const preparedName = item.name.charAt(0).toUpperCase() + item.name.slice(1);
    
    const data = getStepFormData(preparedName);
    
    let preparedData = {
      id: uuidv4(),
      isVisible: true,
      inputsData: []
    };
    
    if (!data) {
      return;
    }
    
    if (preparedName === "Parameters" || preparedName === "Encoder") {
      
      if (!data.properties) {
        return;
      }
      
      const names = Object.keys(data.properties);
      
      names.forEach((name) => {
        // Checking if form can be disabled
        if (name === "enabled") {
          return;
        }
        
        const tempValue = data.properties[name];
        // Main object for result
        let tempResult = {
          name
        };
        
        // Checking type of property and setting default value
        if (tempValue?.type) {
          tempResult.fieldType = "basic";
          tempResult.title = tempValue.title;
          tempResult.type = tempValue.type;
          tempResult.value = tempValue.default ?? "";
          tempResult.required = name === 'name' ? true : !!tempResult.required;
        }
        else {
          tempResult.fieldType = "complex";
          
          const propertyNames = Object.keys(tempValue);
          
          tempResult.options = propertyNames.map((propertyName) => {
            
            const propertyTempValue = tempValue[propertyName];
            let propertyTempResult = {
              name: propertyName,
              properties: []
            };
            
            // Getting all disabled values
            const disabledValues = Object.keys(propertyTempValue.properties)
              .filter((propName) => propName.slice(0, 8) === 'disabled')
              .map((propName) => ({
                name: propName.slice(9),
                value: propertyTempValue.properties[propName].default,
              }));
            
            Object.keys(propertyTempValue.properties).forEach((propName) => {
              
              const item = propertyTempValue.properties[propName];
              
              const isRequired = propertyTempValue.required?.find((item) => item === propName);
              
              let tempProperties = {
                name: propName,
                title: item.title,
                value: item.default ?? "",
                disabled: false,
                required: !!isRequired,
                placeholder: item.placeholder ?? ""
              };
              
              if (propName.slice(0, 8) === 'disabled') {
                return;
              }
              
              const disbaledObject = disabledValues?.find((item) => item.name === propName);
              if (disbaledObject) {
                tempProperties.disabled = disbaledObject.value;
              }
              
              if (item.type) {
                tempProperties.fieldType = "basic";
                tempProperties.complexity = 1;
                
                if (item.enum) {
                  tempProperties.type = "select";
                }
                else {
                  tempProperties.type = item.type;
                  tempProperties.pattern = item.pattern;
                }
              }
              
              propertyTempResult.properties.push(tempProperties);
              
            });
            
            return propertyTempResult;
          });
          
          if (name === "distribution") {
            tempResult.value = tempResult.options.findIndex(item => item.name === "none");
          }
          else {
            tempResult.value = 0;
          }
        }
        
        if (tempValue.required?.find((item) => item === name)) {
          tempResult.isRequired = true;
        }
        
        preparedData.inputsData.push(tempResult);
      });
    }
    
    if (step >= 0) return preparedData;
    
    dispatch(setInitialForms(idx, [ preparedData ]));
  };
  
  if (step >= 0) return prepareData(stepsData[step], step);
  
  stepsData.forEach((item, idx) => {
    prepareData(item, idx);
  });
};