import React from 'react';
import PropTypes from 'prop-types';

import { ButtonLinkStyle } from '../styles/styled-components/Button';
import { IconEdit } from '../styles/styled-components/GlobalIcon';

const ButtonIcon = ({
  className,
  icon,
  onClick 
}) => (

  <ButtonLinkStyle
    className={className}
    type="button"
    onClick={onClick} 
  >
    {icon === "edit" && <IconEdit/>}
  </ButtonLinkStyle>
);

ButtonIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf([ 'edit' ]),
  onClick: PropTypes.func
};

export default ButtonIcon;  