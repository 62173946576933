import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonStep from './ButtonStep';
import { ColCircle,RowCircles, TextStep } from '../styles/styled-components/StepsBar';
import { updateCurrentStep } from '../actions/stepsActions';

const StepsBar = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const stepsData = useSelector((state) => state.steps.get('stepsData'));
  const stepCurrent = useSelector((state) => state.steps.get('stepCurrent'));

  const renderStepsTitle = stepsData.map((step) => ( 
    <ColCircle key={"title"+step.no}>
      <TextStep active={step.state === 'current'}>{t('step.'+step.name)}</TextStep> 
    </ColCircle>
  ));

  const handleClickButtonStep = (step) => {
    dispatch(updateCurrentStep(step.no));
    history.push({ pathname: '/' + step.name });
  };

  const getStrokeColor = (step, isLeftSide) => {
    if (isLeftSide 
      && step.no > 0 
      && (step.no === stepCurrent || [ "done", "visited" ].includes(step.state))) {
      return "#E0197D"; 
    }

    if (!isLeftSide 
      && (step.no === stepCurrent || [ "done", "visited" ].includes(step.state)) 
      && (stepsData[step.no + 1]?.no === stepCurrent || [ "done", "visited" ].includes(stepsData[step.no + 1]?.state))) {
      return "#E0197D"; 
    }
    return "none";    
  };

  const renderStepsCircleState = stepsData.map((step) => (
    <ColCircle className="colCircle" key={"circle"+step.no+"__"+((step.no === stepCurrent) ? "current" : step.state)}>
      <svg height="3" fill="none">
        <path d="M0 1.5H142" stroke={getStrokeColor(step, true)} strokeWidth="2"/>
      </svg>
      <ButtonStep 
        state={(step.no === stepCurrent) ? "current" : step.state} 
        stepName={step.name} 
        isDisabled={step.state === "default"}
        onButtonClick={() => handleClickButtonStep(step)}
      />
      <svg height="3" fill="none">
        <path d="M0 1.5H142" stroke={getStrokeColor(step, false)} strokeWidth="2"/>
      </svg>
    </ColCircle>
  ));
  
  return (
    <Container className="my-4">
      <Row>
        {renderStepsTitle}
      </Row>
      <RowCircles>
        {renderStepsCircleState}
      </RowCircles>
    </Container>
  );
};

export default StepsBar;