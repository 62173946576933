import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'; 

import FormCard from '../components/FormCard';
import { addForm } from '../actions/formsDataActions';
import { AddNewFormButton } from '../styles/styled-components/FormCardContainer';
import useApiFormData from '../hooks/useApiFormData';

import PlusIcon from '../../assets/svg/icons/plus.svg';

const FormCardContainer = ({ withoutEye, invalidProps, setInvalidProps, encoder,
  arrayParamName, propsDuplicateNameFormIds, setPropsDuplicateNameFormIds }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stepCurrent = useSelector((state) => state.steps.get('stepCurrent'));
  const formsData = useSelector((state) => state.formsData.getIn([ stepCurrent ]));

  const defaultFormData = useApiFormData(stepCurrent);

  const addFormHandler = () => {
    dispatch(addForm(stepCurrent, defaultFormData));

    setInvalidProps((prev) => ({
      ...prev,
      [defaultFormData.id]: []
    }));
  };

  return (
    <div className="mt-5">
      {formsData?.map((form, idx) => (
        <FormCard
          key={form.id}
          formData={form}
          formsDataLength={formsData?.length}
          idx={idx}
          withoutEye={withoutEye}
          invalidProps={invalidProps}
          setInvalidProps={setInvalidProps}
          propsDuplicateNameFormIds={propsDuplicateNameFormIds}
          setPropsDuplicateNameFormIds={setPropsDuplicateNameFormIds}
          arrayParamName={arrayParamName}
        />
      ))}
      <AddNewFormButton onClick={addFormHandler}>
        <img src={PlusIcon} alt="Add new" />
        <span>{encoder ? t('cards.add_encoder') : t('cards.add_parameter')}</span>
      </AddNewFormButton>
    </div>
  );
};

FormCardContainer.propTypes = {
  withoutEye: PropTypes.bool,
  invalidProps: PropTypes.object, 
  setInvalidProps: PropTypes.func,
  encoder: PropTypes.bool,
  arrayParamName: PropTypes.array,
  propsDuplicateNameFormIds: PropTypes.array,
  setPropsDuplicateNameFormIds: PropTypes.func
};

export default FormCardContainer;