import React from 'react';
import PropTypes from 'prop-types';

import { LabelStyle, RadioStyleBase, Wrapper } from '../styles/styled-components/Form';

const CustomRadioButton = ({ className, id, checked, label, onChange }) => {

  return (
    <Wrapper className={className ?? ""}>
      <RadioStyleBase type="radio" id={"radio-"+id} tabIndex="0" onChange={onChange} checked={checked}/> 
      <LabelStyle htmlFor={"radio-"+id}>{label}</LabelStyle>
    </Wrapper>
  );
};

CustomRadioButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string, 
  id: PropTypes.string, 
  label: PropTypes.string,
  onChange: PropTypes.func
};

export default CustomRadioButton;