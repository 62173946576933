import styled from 'styled-components';

export const ContainerError = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const ImgErrorStyle = styled.img`
  width: 320px;
  height: 320px;
`;