import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomSelect } from '../styles/styled-components/CustomInput';

const CustomInputSelect = ({ className, options, id, invalid, isDisabled, value, onChangeFun }) => {
  const { t } = useTranslation();

  return (
    <CustomSelect
      className={(invalid ? "is-invalid": "")+ " " + (className ?? "")}
      tabIndex="0"
      id={id} 
      classNamePrefix="react-select" 
      placeholder={t('common.select')}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#e0197d',
          primary25: '#FDEBF4',
          primary50: '#FABBDB'
        }
      })}
      options={options} 
      isClearable={false} 
      isDisabled={isDisabled}
      isSearchable={false}
      value={value}
      onChange={(e) => onChangeFun(e)}/>
  );
};

CustomInputSelect.propTypes = { 
  className: PropTypes.string,
  invalid: PropTypes.bool,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.array, 
  value: PropTypes.oneOfType([ PropTypes.array, PropTypes.object, PropTypes.string ]),
  onChangeFun: PropTypes.func
};

export default CustomInputSelect;
