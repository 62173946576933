import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerError, ImgErrorStyle } from '../styles/styled-components/ErrorPage';
import ButtonLink from '../components/ButtonLink';
import { TitleH2 } from '../styles/styled-components/GlobalFonts';
import ImageError from '../../assets/img/error_img.png';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <ContainerError className="d-flex align-items-center flex-column justify-content-center">
      <ImgErrorStyle src={ImageError} alt={t('img_alt.error_page')}/>
      <TitleH2 className='mb-5'>{t('error.no_loading_data')}</TitleH2>
      <ButtonLink 
        to="/" 
        text={t('common.go_back_to_main_page')}
      />
    </ContainerError>
  );
};

export default ErrorPage;