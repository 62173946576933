import styled from 'styled-components';
import { Col, Row } from 'reactstrap';

export const RowCircles = styled(Row)`
  background-color: ${props => props.theme.colors.gray.type_000};
  height: 19px;
  border-radius: 16px;
  margin-top: 5px;
`;

export const ColCircle = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;

  svg {
    width: 50%;
    position: absolute;
    z-index: 2;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }

  }
`;

export const ButtonStepStyle = styled.button`
  height: ${props => props.isCurrent ? "19px" : "14px"};
  display: flex;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 3;

  &:disabled {
    pointer-events: none;
    position: absolute;
    z-index: 1;
  };
`;

export const ButtonStepImg = styled.img`
  width: ${props => props.isCurrent ? "19px" : "14px"};
  height: ${props => props.isCurrent ? "19px" : "14px"};
  cursor: pointer;
`;

export const TextStep = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 150%;
  letter-spacing: 0.28px;
  color: ${props => props.active ? props.theme.colors.pink.type_600 : props.theme.colors.gray.type_500};
`;