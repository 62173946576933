
export const setSelectedDataApp = (name, properties) => {
  return {
    type: 'SET_SELECTED_DATA_APP',
    payload: {
      name,
      properties
    }
  };
};

export const updatePropertyApp = (selectedOption, propName, propValue) => {
  return {
    type: 'UPDATE_PROPERTY_APP',
    payload: {
      selectedOption,
      propName,
      propValue
    }
  };
}; 

export const resetApplication = {
  type: 'RESET_APPLICATION',
};