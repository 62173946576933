import { Map } from 'immutable';

//state: default, current, done
const initialState = Map({
  stepCurrent: -1,
  stepDone: -1,
  stepsData: [
    { no: 0, name: "parameters", state: "default" },
    { no: 1, name: "method", state: "default" },
    { no: 2, name: "encoder", state: "default" },
    { no: 3, name: "decoder", state: "default" },
    { no: 4, name: "application", state: "default" },
    { no: 5, name: "execution", state: "default" }
  ]
});

export const stepsReducer = (state = initialState, action) => {
  const currentStateValue = state.get('stepCurrent');

  switch (action.type) {
  case 'START': 
    return state
      .setIn([ 'stepsData', 0 ], { ...state.getIn([ 'stepsData', 0 ]), state: "current" })
      .set('stepCurrent', 0);
  case 'LAST_STEP': 
    return state.set('stepCurrent', currentStateValue - 1);
  case 'NEXT_STEP': 
    return state
      .setIn([ 'stepsData', currentStateValue ], { ...state.getIn([ 'stepsData', currentStateValue ]), state: "done" })
      .set('stepCurrent', currentStateValue + 1)
      .set('stepDone', (state.get('stepDone') < currentStateValue) ? currentStateValue : state.get('stepDone'));
  case 'UPDATE_CURRENT_STEP':
    const lastCurrentStep = (currentStateValue > state.get('stepDone')) ? "visited" : "done";
    return state
      .setIn([ 'stepsData', currentStateValue ], { ...state.getIn([ 'stepsData', currentStateValue ]), state: lastCurrentStep })
      .set('stepCurrent', action.payload);
  case 'RESET_STEP': 
    return initialState;
  default:
    return state;
  }  
};