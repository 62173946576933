import styled from 'styled-components';

export const LoadingParent = styled.div`
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255, 0.65);
    z-index: 10000;
`;

export const LoadingBox = styled.div`
    max-width: 100px;
    max-height: 100px;
    position: relative;
    left: 50%;
    top: 40%;
`;

export const LoadingImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    animation-delay: 0ms;
    animation: rotation 1500ms infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
`;

export const LoadingImgAlone = styled.img`
  animation: rotation 1500ms infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;