import { toastr } from 'react-redux-toastr';
import iconError from '../../assets/svg/icons/notify/error.svg';
import iconSuccess from '../../assets/svg/icons/notify/sucess.svg';
import iconWarning from '../../assets/svg/icons/notify/warning.svg';
import iconInfo from '../../assets/svg/icons/notify/info.svg';

const toastrErrorOptions = {
  removeOnHover: false,
  closeOnToastrClick: true,
  icon: (<img src={iconError} alt="" width="20px"/>)
};

export const error = (title, message) => {
  toastr.error(title, message, toastrErrorOptions);
};

const toastrSuccessOptions = {
  timeOut: 5000,
  removeOnHover: true,
  closeOnToastrClick: true,
  icon: (<img src={iconSuccess} alt="" width="22px"/>)
};

export const success = (title, message) => {
  toastr.success(title, message, toastrSuccessOptions);
};

const toastrWarningOptions = {
  timeOut: 6000,
  removeOnHover: true,
  closeOnToastrClick: true,
  icon: (<img src={iconWarning} alt="" width="20px"/>)
};

export const warning = (title, message) => {
  toastr.warning(title, message, toastrWarningOptions);
};

const toastrInfoOptions = {
  timeOut: 5000,
  removeOnHover: true,
  closeOnToastrClick: true,
  icon: (<img src={iconInfo} alt="" width="22x"/>)
};

export const info = (title, message) => {
  toastr.info(title, message, toastrInfoOptions);
};
