//TODO temporary copy from https://git.man.poznan.pl/stash/projects/QCGNG/repos/external-gui-form-toolbox/raw/index.js
//TODO try to submodule reesolution in feature :)
export function sendMessage(message, targetOrigin = '*') {
  window.top.postMessage(message, targetOrigin);
}

export const getFile = (fileName, targetOrigin) => new Promise((resolve, reject) => {
  const channel = new MessageChannel();

  channel.port1.onmessage = e => {
    if ((targetOrigin && targetOrigin === e.origin) || !targetOrigin) {
      if (e.data.id === 'getFile') {
        if (e.data.error)
          reject(e.data.error);
        else
          resolve(e.data.data);
      }
    }
  };

  window.top.postMessage({
    data: fileName,
    error: null,
    id: 'getFile'
  }, targetOrigin ?? '*', [ channel.port2 ]);
});

export const getTemplate = (targetOrigin) => new Promise((resolve, reject) => {
  const channel = new MessageChannel();
  channel.port1.onmessage = e => {
    if ((targetOrigin && targetOrigin === e.origin) || !targetOrigin) {
      if (e.data.id === 'getTemplate') {
        if (e.data.error)
          reject(e.data.error);
        else
          resolve(e.data.data);
      }
    }
  };
  
  window.top.postMessage({
    id: 'getTemplate'
  }, targetOrigin ?? '*', [ channel.port2 ]);
});