import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CustomInput from './CustomInput';
import FileDefineModule from './FileDefineModule';
import {
  FormContainer,
  FormCardContainer,
  FormComplexInputContainer,
  CardActionsContainer,
  CardActionButton
} from '../styles/styled-components/FormCard';
import { setInputData, setComplexInputData, setFormsOrder, toggleFormVisibility, deleteForm } from '../actions/formsDataActions';
import { getErrorValidMsg, checkIsPropValidCustom, isPropertyInInvalidArray } from '../utils/validationFunc';
import { convertStringToBase64, convertBase64ToString } from '../utils/formUtils';

import ArrowUpIcon from '../../assets/svg/icons/card-actions/arrow_up.svg';
import EyeIcon from '../../assets/svg/icons/card-actions/eye.svg';
import DisabledEyeIcon from '../../assets/svg/icons/card-actions/disabled_eye.svg';
import MinusIcon from '../../assets/svg/icons/card-actions/minus.svg';
import ArrowDownIcon from '../../assets/svg/icons/card-actions/arrow_down.svg';

const FormCard = ({ formData, formsDataLength, idx, withoutEye, invalidProps, setInvalidProps,
  arrayParamName, propsDuplicateNameFormIds, setPropsDuplicateNameFormIds }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currFileData = useRef(null);
  const [ showModal, setShowModal ] = useState(false);
  const tempFileDataForUpdate = useRef(null);

  const { id: formId, isVisible, inputsData } = formData;
  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const isStepParameters = currentStepNo === 0;

  //TODO dodanie useeffect i sprwawdzenie czy dany form name nie jest w tablicy duplikacji, jesli tak to dodac mu invalidmsg
  
  const inputChangeHandler = (event, inputData, complexData) => {
    const { name, value, checked, type } = event.target;

    const { required } = inputData;

    if (!(name || checked)) return;
    handleCheckPropValue(value, name, { type: inputData.type, pattern: inputData.pattern }, required);

    if (type === 'select-one') {
      setInvalidProps((prev) => ({
        ...prev,
        [formId]: []
      }));
    }

    let finalValue = value;
    // It commented due to problem with input value 0.0 > changed to 0 and user cannot type 0.01
    // if ((type === 'number' && value !== '') || type === 'select-one') {
    //   finalValue = Number(value);
    // }
  
    const { complexity, name: propertyName } = inputData;

    // If finalValue is empty, set it to empty string
    finalValue = finalValue ?? '';

    // If input is complex, set complex input data
    if (complexity) {
      const { inputName, optionName } = complexData;

      if (type === 'checkbox') {

        const dataWithoutProperty = invalidProps[formId]?.filter(item => item !== propertyName);

        setInvalidProps((prev) => ({
          ...prev,
          [formId]: dataWithoutProperty
        }));

        return dispatch(
          setComplexInputData(
            currentStepNo,
            formId,
            inputName,
            optionName,
            propertyName,
            {
              ...inputData,
              disabled: !checked
            }
          )
        );
      }

      if (type === 'select-one') {
        dispatch(setInputData(currentStepNo, formId, inputName, finalValue));
        // If sampling sapce has been set to "string" then set distribution to "none"
        if (inputName === 'sampling_space' && finalValue === 2) {
          dispatch(setInputData(currentStepNo, formId, "distribution", 0));
        }
      }
      return dispatch(
        setComplexInputData(
          currentStepNo,
          formId,
          inputName,
          optionName,
          propertyName,
          {
            ...inputData,
            value: finalValue
          }
        )
      );
    } 

    dispatch(
      setInputData(currentStepNo, formId, name, finalValue)
    );
  };

  const modalChangeHandler = (property, complexData) => {
    tempFileDataForUpdate.current = {
      inputData: property, 
      complexData
    };
    
    //TODO usunac ten fragment kodu jesli nie bedzie juz konwersji z stringa na base64 w funkcji handleConfirmSaveFileData
    let fileData;
    if (property.value) {
      fileData = {};
      fileData.resource_type = property.value?.resource_type;
      if (property.value?.resource_type === "content") {
        fileData.content = convertBase64ToString(property.value?.content);
      }
      else {
        fileData.path = property.value?.path;
      }
    }

    currFileData.current = fileData;  
    setShowModal(true);
  };

  const removeElementFromDuplicatedArray = (idxOfLastVersionParamName) => {
    let locArrayDuplicatedNames = [ ...propsDuplicateNameFormIds ];
    locArrayDuplicatedNames = locArrayDuplicatedNames.filter(item => item !== formId);
    if (arrayParamName[idxOfLastVersionParamName].formIds.length === 1) {
      arrayParamName.splice(idxOfLastVersionParamName, 1);
    }
    else {
      if (arrayParamName[idxOfLastVersionParamName].formIds.length === 2) {
        locArrayDuplicatedNames = locArrayDuplicatedNames.filter(
          item => item !== arrayParamName[idxOfLastVersionParamName].formIds.find(id => id !== formId));
      }
      arrayParamName[idxOfLastVersionParamName].formIds = arrayParamName[idxOfLastVersionParamName].formIds.filter(id => id !== formId);
    }
    setPropsDuplicateNameFormIds(locArrayDuplicatedNames);
  };
  
  const checkIsParamNameIsDuplicate = (value) => {
    let isDifferentValue = false;
    let idxOfLastVersionParamName = arrayParamName.findIndex(item => item.formIds.includes(formId));
    if (idxOfLastVersionParamName !== -1) {
      isDifferentValue = arrayParamName[idxOfLastVersionParamName].key !== value;
      
      if (isDifferentValue) {
        removeElementFromDuplicatedArray(idxOfLastVersionParamName);
      }
    }
    
    if (idxOfLastVersionParamName === -1 || isDifferentValue) {
      const idxOfArrayExistKey = arrayParamName.findIndex(item => item.key === value);
      if (idxOfArrayExistKey !== -1) {
        let duplicatedFormIds = [ formId ];
        if (arrayParamName[idxOfArrayExistKey].formIds.length === 1
          && (propsDuplicateNameFormIds.length === 0 || !propsDuplicateNameFormIds?.find(id => id === formId))) {
          duplicatedFormIds.push(arrayParamName[idxOfArrayExistKey].formIds[0]);
        }
        arrayParamName[idxOfArrayExistKey].formIds.push(formId);
        setPropsDuplicateNameFormIds((prev) => ([ ...prev, ...duplicatedFormIds ]));
      } 
      else {
        arrayParamName.push({ key: value, formIds: [ formId ] });
      }
    }
  };
  
  const handleCheckPropValue = (value, propertyName, validInfo, isRequired, isBlur) => {
    checkIsPropValidCustom(value, propertyName, validInfo, invalidProps, setInvalidProps, isRequired, formId);
    if (isBlur && propertyName === "name" && value) {
      checkIsParamNameIsDuplicate(value);
    }
  }; 

  const handleConfirmSaveFileData = (value, fileFormat) => {
    if (tempFileDataForUpdate.current === null) return;

    const { inputData, complexData } = tempFileDataForUpdate.current;

    const { inputName, optionName } = complexData;
    const { required, name: propertyName } = inputData;

    handleCheckPropValue(value, propertyName, { type: "file" }, required);

    let result = {};

    result.resource_type = fileFormat;
    if (fileFormat === "path") {
      result.path = value;
    }
    else {
      //TODO przeniesc zmiane stringa na base64 do funkcji zapisujacej do pliku lub wysylajacej dane do zadania w qcg
      result.content = convertStringToBase64(value);
    }

    dispatch(setComplexInputData(
      currentStepNo,
      formId,
      inputName,
      optionName,
      propertyName,
      {
        ...inputData,
        value: result
      }
    ));
    
    currFileData.current = null;
    setShowModal(false);
  };

  const renderInputs = (input) => {

    if (input.fieldType === 'basic') {
      return renderInput(input);
    }

    let options = [];
    let complexData = {};
    const selectedOption = input.value;

    if (input.fieldType === 'complex') {
      options = input.options.map((option, idx) => {return { name: option.name, value: idx }; });
      complexData = {
        inputName: input.name,
        optionName: input.options[selectedOption].name
      };
    }

    let isInvalid;

    return (
      <FormComplexInputContainer key={`container-${input.options[selectedOption].name}`}>
        {
          input.options[selectedOption].properties.map((property, idx) => {

            isInvalid = isPropertyInInvalidArray(property.name, invalidProps[formId]);

            return (
              <CustomInput
                key={`${input.name}-${idx}`}
                typeOfInput={property.type === "select" ? "select-def" : property.type}
                checkbox={!property.required}
                isInputDisabled={property.disabled}
                isVisible={isVisible}
                invalidMsg={isInvalid ? getErrorValidMsg(idx === 0 ? input.value : property.value, { type: property.type }) : ""}
                isInvalid={(isInvalid && !property.disabled)}
                isRequired={property.required}
                name={property.name}
                label={property.title}
                //placeholder={property.value === null ? t('card.none') : undefined}
                placeholder={property.placeholder ? property.placeholder : t('card.none')}
                value={idx === 0 ? 
                  input.value 
                  :
                  property.type === "file" ?
                    property.value.resource_type 
                    :
                    String(property.value)
                }
                selectOptionsDef={options}
                handleBlur={(e) => handleCheckPropValue(e.target.value, property.name, { type: property.type, pattern: property.pattern }, property.required)}
                handleChange={(e) => property.type === "file" ? 
                  modalChangeHandler(property, complexData)
                  :
                  inputChangeHandler(e, property, complexData, true)
                }
              />
            );
          })
        }
      </FormComplexInputContainer>
    );

  };
  
  const deleteFormBox = () => {
    if (currentStepNo === 0) {
      let idxOfLastVersionParamName = arrayParamName.findIndex(item => item.formIds.includes(formId));
      if (propsDuplicateNameFormIds.find(item => item === formId)) {
        removeElementFromDuplicatedArray(idxOfLastVersionParamName);
      }
      else {
        arrayParamName.splice(idxOfLastVersionParamName, 1);
      }
    }
  
    if (invalidProps[formId]?.length > 0) {
      setInvalidProps((prevData) => {
        const newData = { ...prevData };
        delete newData[formId];
        return newData;
      });
    }
    
    dispatch(deleteForm(currentStepNo, formId));
  };
  

  const renderInput = (input) => {

    // Find index of input in inputsData array
    const inputNo = inputsData?.findIndex((inputData) => inputData.name === input.name);
    // Get input data from inputsData array if inputNo is found
    const inputData = inputNo > -1 ? inputsData[inputNo] : null;

    const isDuplicatedParamName = isStepParameters && propsDuplicateNameFormIds.includes(formId);
    const isInvalid = isPropertyInInvalidArray(input.name, invalidProps[formId]) || isDuplicatedParamName;
    let invalidMsg = "";
    if (isInvalid) {
      if (isDuplicatedParamName) {
        invalidMsg = t('validation.error') + t('validation.msg.invalid_exist_param_name_value');
      }
      else {
        invalidMsg = getErrorValidMsg(idx === 0 ? input.value : input.value, { type: input.type });
      }
    }
    
    return (
      <CustomInput
        key={input.id ?? input.name}
        typeOfInput={input.typeOfInput}
        checkbox={input.checkbox}
        isInputDisabled={inputData === null}
        isVisible={isVisible}
        invalidMsg={invalidMsg}
        isInvalid={isInvalid}
        isRequired={input.required}
        name={input.name}
        label={input.title}
        placeholder={inputData === null ? t('card.none') : undefined}
        value={inputData?.value}
        selectOptionsDef={input.selectOptionsDef}
        handleChange={(e) => inputChangeHandler(e, inputData)}
        handleBlur={(e) => handleCheckPropValue(e.target.value, input.name, { type: input.type }, input.required, true)}
        extraWidth
      />
    );
  };

  return (
    <FormContainer>
      <FormCardContainer isVisible={isVisible}>
        {
          formData.inputsData.map((input) => renderInputs(input))
        }
      </FormCardContainer>
      <CardActionsContainer withoutEye={withoutEye ? 1 : 0}>
        <CardActionButton 
          disabled={idx === 0} 
          onClick={() => dispatch(setFormsOrder(currentStepNo, idx, idx - 1))}
        >
          <img src={ArrowUpIcon} alt="Arrow Up" />
        </CardActionButton>
        {
          !withoutEye && (
            <CardActionButton onClick={() => dispatch(toggleFormVisibility(currentStepNo, formId))}>
              <img src={isVisible ? EyeIcon : DisabledEyeIcon} alt="Eye" />
            </CardActionButton>
          )
        }
        <CardActionButton onClick={() => deleteFormBox(currentStepNo, formId)}>
          <img src={MinusIcon} alt="Minus" />
        </CardActionButton>
        <CardActionButton 
          disabled={idx === formsDataLength - 1}
          onClick={() => dispatch(setFormsOrder(currentStepNo, idx, idx + 1))}
        >
          <img src={ArrowDownIcon} alt="Arrow Down" />
        </CardActionButton>
      </CardActionsContainer>
      <FileDefineModule 
        isShowModal={showModal}
        fileData={currFileData.current}
        onClickCancelModal={()=>setShowModal(false)}
        onClickConfirmModal={handleConfirmSaveFileData}
      />
    </FormContainer>
  );
};

FormCard.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    isVisible: PropTypes.bool,
    inputsData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      })
    ),
  }),
  invalidProps: PropTypes.object, 
  setInvalidProps: PropTypes.func,
  formsDataLength: PropTypes.number,
  idx: PropTypes.number,
  withoutEye: PropTypes.bool,
  arrayParamName: PropTypes.array,
  propsDuplicateNameFormIds: PropTypes.array,
  setPropsDuplicateNameFormIds: PropTypes.func
};

export default FormCard;