import { checkIsPropValid, isPropertyInInvalidArray } from './validationFunc';

export const getSelectSetForBoolean = () => {
  return [
    {
      "label": "true",
      "value": true
    },
    {
      "label": "false",
      "value": false
    }
  ];
};

export const getSelectedSetFromEnum = (enumSet) => {
  let selectSet = [];
  enumSet.forEach(item => {
    selectSet.push({
      "label": item,
      "value": item
    });
  });
  return selectSet;
};

export const getSelectedSetFromObjectEntries = (objectEntries) => {
  let selectSet = [];
  Object.entries(objectEntries).forEach(item => {
    selectSet.push({
      "label": item[0],
      "value": item[0]
    });
  });
  return selectSet;
};

export const convertAndCheckedValidOfValueToReduxSave = (event, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps) => {
  if (validInfo.type === "select" || validInfo.type === "boolean") {
    if (isPropertyInInvalidArray(propertyName, invalidProps) && event !== "") {
      setInvalidProps(invalidProps.filter(item => item !== propertyName));
    }
    return event;
  }
  else {
    let value = event.target.value;
    if (validInfo.type === "integer") {
      value = parseInt(value);
      if (isPropertyInInvalidArray(propertyName, invalidProps)) {
        if (validInfo.min) {
          if (value >= validInfo.min) {
            setInvalidProps(invalidProps.filter(item => item !== propertyName));    
          }
        }
        else {
          if (value >= 0) {
            setInvalidProps(invalidProps.filter(item => item !== propertyName));    
          }
        }
      }
    } 
    else {
      checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps);
    } 
    return value;
  }
};

export const convertSchemaTypeToInputType = (schemaType) => {
  switch (schemaType) {
  case "integer":
    return "number";
  case "number":
    return "float";
  case "string":
    return "text";  
  case "file":
    return "text";
  default:
    return schemaType;
  }
};

export const getCurrPropertyValidInfo = (propertyName, selectedObject) => {
  return selectedObject.find(property => property.get('name') === propertyName).get("validInfo");
};

export const convertStringToBase64 = (text) => {
  const bytes = new TextEncoder().encode(text);
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
};

export const convertBase64ToString = (base64) => {
  const binString = atob(base64);
  let bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
  return new TextDecoder().decode(bytes);
};