
export const setTaskName = (name) => {
  return {
    type: 'SET_TASK_NAME',
    payload: name
  };
}; 

export const resetTaskName = {
  type: 'RESET_TASK_NAME',
};

export const setLinkDoc = (link) => {
  return {
    type: 'SET_LINK_DOC',
    payload: link
  };
}; 