import styled from 'styled-components';
import { Label } from 'reactstrap';

export const ContainerRowText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-width: 200px;
    
  p {
    max-width: 516px;
    width: 100%;
  }
`;

export const TextLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.font.gray};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 120%;
  max-width: 230px;
  width: 25%;
  margin-right: 24px;
  margin-bottom: 0;
`;