export const setStart = {
  type: 'START',
};

export const setLastStep = {
  type: 'LAST_STEP',
};

export const setNextStep = {
  type: 'NEXT_STEP',
};

export const resetStep = {
  type: 'RESET_STEP',
};

export const updateCurrentStep = (stepNumber) => {
  return {
    type: 'UPDATE_CURRENT_STEP',
    payload: stepNumber
  };
}; 