import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import TopBar from './topbar';
import { Para14, Para14Thin, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { BoxGrey20, BoxGrey24 } from '../styles/styled-components/GlobalStyle';
import CustomRadioButton from '../components/CustomRadioButton';
import ButtonALink from '../components/ButtonALink';
import { convertPropertiesToReduxPropertiesArray, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { convertAndCheckedValidOfValueToReduxSave, getSelectedSetFromEnum, getCurrPropertyValidInfo } from '../utils/formUtils';
import { setSelectedDataMethod, updatePropertyMethod } from '../actions/methodActions';
import { getErrorValidMsg, checkIsPropValid, checkIsFormValid, isPropertyInInvalidArray } from '../utils/validationFunc';
import ErrorPage from '../components/ErrorPage';

const StepMethod = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ invalidProps, setInvalidProps ] = useState([]);
  const [ statusValidStep, setStatusValidStep ] = useState();
  const currLang = navigator.language;

  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const schemaFormData = getStepFormData("Method");
  const selectedMethod = useSelector((state) => state.method.get('selectedOption'));
  const methodsProperties = useSelector((state) => state.method.get('properties'));
  const selectedMethodProps = useSelector((state) => state.method.getIn([ 'properties', selectedMethod ]));
  const requiredProps = schemaFormData[selectedMethod]?.required;
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const contentData = getContentOfStep("method"); 
  
  const arrayRadio = [
    { id: "mc", label: t('step_method.method_mc.name'), doc: "/monte-carlo/" }, 
    { id: "sc", label: t('step_method.method_sc.name'), doc: "/sc/" },
    { id: "pce", label: t('step_method.method_pce.name'), doc: "/pce/" },
    { id: "basic", label: t('step_method.method_basic.name'), doc: "/introduction/" }, 
    { id: "sweep", label: t('step_method.method_sweep.name'), doc: "/introduction/" }
  ];
  
  const handleTopbarBtnClick  = () => {
    checkIsFormValid(selectedMethod, requiredProps, selectedMethodProps, setInvalidProps, setStatusValidStep, "method");
  };

  const handleChangeMethod = (event) => {
    const selectedMethodName = (event.target.id).split('-')[1];
    let currProps = methodsProperties.get(selectedMethodName);
    if (currProps.size === 0) {
      currProps = convertPropertiesToReduxPropertiesArray(schemaFormData[selectedMethodName].properties);
    }
    dispatch(setSelectedDataMethod(selectedMethodName, currProps));
    setInvalidProps([]);
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps)) {
      dispatch(updatePropertyMethod(selectedMethod, propertyName, value));
    }
  };  

  const handleChangePropValue = (event, propertyName, validInfo) => {
    const value = convertAndCheckedValidOfValueToReduxSave(event, propertyName, validInfo, invalidProps, setInvalidProps);
    dispatch(updatePropertyMethod(selectedMethod, propertyName, value));
  };

  const renderSchemaJsonFormData = () => {
    const formElem = schemaFormData[selectedMethod].properties;
    let currPropertyRedux;  
    let isInvalid;
    return (
      <Form>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedMethodProps.find(property => property.get('name') === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          return <CustomInput
            key={"key_"+item[0]}
            label={item[1].title}
            invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.get("value"), getCurrPropertyValidInfo(item[0], selectedMethodProps)) : ""}
            isInvalid={isInvalid}
            isRequired={requiredProps.includes(item[0])}
            isRowElemDirection={true}
            min={item[1].min || 0}
            name={item[0]}
            placeholder={item[1].placeholder || t('card.none')}
            selectOptions={item[1].enum ? getSelectedSetFromEnum(item[1].enum) : []}
            typeOfInput={currPropertyRedux.getIn([ "validInfo", "type" ])}
            value={currPropertyRedux.get("value")}
            handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedMethodProps))}
            handleChange={(e) => handleChangePropValue(e, item[0], getCurrPropertyValidInfo(item[0], selectedMethodProps))}
          />;       
        })
        }
      </Form>
    );
  };

  if (currentStepNo === -1) { //after refresh page
    return (
      <ErrorPage/> 
    );
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_method.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick} statusValid={statusValidStep}/>
      <div>
        <TitleH3 className='mt-12'>{t('step_method.title_method_selection')}</TitleH3>
        <Para14 className='mt-2'>{t('step_method.description_selection')}</Para14>
        <hr className='my-12'/>
        <Row className="mb-4">
          <Col>
            {arrayRadio.map(item => {
              return <CustomRadioButton 
                className="mb-4"
                key={item.id}
                id={item.id} 
                label={item.label}
                checked={item.id === selectedMethod}
                onChange={handleChangeMethod}
              />;  
            })}
          </Col>
          { selectedMethod 
            && <Col className="ml-2">
              <BoxGrey20>
                <Para14Thin>
                  {contentData?.[selectedMethod].description[currLang]}
                </Para14Thin>
              </BoxGrey20>
              <ButtonALink
                className="float-right mt-2"
                href={linkDocumentation + "concepts/methods" + arrayRadio.find(radio => radio.id === selectedMethod).doc}
                icon="documentation"
                text={t('button.go_to_documentation')}            
              />
            </Col>
          }
        </Row>
        { selectedMethod 
          && <>
            <hr className='mb-4'/>
            <div>
              <TitleH3>{t('step_method.title_method_parameters')}</TitleH3>
              <Para14 className='mt-2'>{contentData?.subtitle_param}</Para14>
              { selectedMethod && <BoxGrey24>{ renderSchemaJsonFormData() }</BoxGrey24> }
              {/* <Para14Thin className='mt-2'>???Wskazwóki do parametrów metody</Para14Thin> */}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default StepMethod;
