import { setSelectedDataApp } from '../actions/applicationAction';
import { setSelectedDataDecoder } from '../actions/decoderActions';
import { setInitialAdvancedParallelProps, setSelectedDataExecution, setInitialExecutionQCGData } from '../actions/executionActions';
import { setSelectedDataMethod } from '../actions/methodActions';
import { convertPropertiesToReduxPropertiesArray, getQCGProperties, getStepFormData, setExecutionAdvancedParallelProps } from '../utils/jsonSchemaParser';

export const initSchemaData = (dispatch) => {
  let schemaFormData, props;

  //METHOD
  schemaFormData = getStepFormData("Method");
  props = convertPropertiesToReduxPropertiesArray(schemaFormData["mc"].properties);
  dispatch(setSelectedDataMethod("mc", props));

  //DECODER
  schemaFormData = getStepFormData("Decoder");
  props = convertPropertiesToReduxPropertiesArray(schemaFormData["csv"].properties);
  dispatch(setSelectedDataDecoder("csv", props));

  //APPLICATION
  schemaFormData = getStepFormData("Application");
  props = convertPropertiesToReduxPropertiesArray(schemaFormData["preinstalled"].properties);
  dispatch(setSelectedDataApp("preinstalled", props));

  //EXECUTION
  schemaFormData = getStepFormData("Execution");
  props = convertPropertiesToReduxPropertiesArray(schemaFormData["parallel"].properties);
  dispatch(setSelectedDataExecution("serial", props));

  props = getQCGProperties();
  dispatch(setInitialExecutionQCGData(props));

  props = setExecutionAdvancedParallelProps();
  dispatch(setInitialAdvancedParallelProps(props));
};
