import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ButtonTextWhiteStyle } from '../styles/styled-components/Button';

const ButtonLink = ({ isGoBack, text, to }) => {
  const history = useHistory();

  const onHandleClickLink = () => {
    if (isGoBack) {
      history.goBack();
    }
    else {
      history.push(to);
    }
  };

  return (
    <ButtonTextWhiteStyle onClick={() => onHandleClickLink()}>
      {text}
    </ButtonTextWhiteStyle>
  );
};

ButtonLink.propTypes = {
  isGoBack: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.string
};

export default ButtonLink;