
export const setSelectedDataDecoder = (name, properties) => {
  return {
    type: 'SET_SELECTED_DATA_DECODER',
    payload: {
      name,
      properties
    }
  };
};

export const updatePropertyDecoder = (selectedOption, propName, propValue) => {
  return {
    type: 'UPDATE_PROPERTY_DECODER',
    payload: {
      selectedOption,
      propName,
      propValue
    }
  };
}; 

export const resetDecoder = {
  type: 'RESET_DECODER',
};