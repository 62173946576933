import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import { ReactComponent as InfoIcon } from '../../assets/svg/icons/common/info.svg';

const TooltipInfo = ({ children, elemName, placement, text }) => {
  const [ showTooltip, setShowTooltip ] = useState(false);
  
  return (
    <>
      <InfoIcon className="ml-1 mb-2" id={"tooltip-"+elemName}/>
      <Tooltip
        className="tooltipStyle"
        isOpen={showTooltip}
        placement={placement || "top"}
        target={"tooltip-"+elemName}
        toggle={() => setShowTooltip(showTooltip => !showTooltip)}
      >
        {text || children}
      </Tooltip>
    </>
  );
};

TooltipInfo.propTypes = {
  children: PropTypes.node,
  elemName: PropTypes.string,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  text: PropTypes.string
};
  
export default TooltipInfo;