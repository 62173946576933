const namespace = "@uqsa-ui/formsData";

export const actionTypes = {
  INIT_FORMS: `${namespace}/INIT_FORMS`,
  SET_INPUT_DATA: `${namespace}/SET_INPUT_DATA`,
  SET_COMPLEX_INPUT_DATA: `${namespace}/SET_COMPLEX_INPUT_DATA`,
  ADD_INPUT: `${namespace}/ADD_INPUT`,
  DELETE_INPUT: `${namespace}/DELETE_INPUT`,
  TOGGLE_FORM_VISIBILITY: `${namespace}/TOGGLE_FORM_VISIBILITY`,
  SET_FORMS_ORDER: `${namespace}/SET_FORMS_ORDER`,
  ADD_FORM: `${namespace}/ADD_FORM`,
  DELETE_FORM: `${namespace}/DELETE_FORM`,
};

export const setInitialForms = (stepNo, forms) => (dispatch) => {
  dispatch({
    type: actionTypes.INIT_FORMS,
    payload: {
      stepNo,
      forms,
    },
  });
};

export const setInputData = (stepNo, formId, inputName, data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INPUT_DATA,
    payload: {
      stepNo,
      formId,
      inputName,
      data,
    },
  });
};

export const setComplexInputData = (stepNo, formId, inputName, optionName, propertyName, data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COMPLEX_INPUT_DATA,
    payload: {
      stepNo,
      formId,
      inputName,
      optionName,
      propertyName,
      data
    },
  });
};

export const addInput = (stepNo, formId, data) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_INPUT,
    payload: {
      stepNo,
      formId,
      data,
    },
  });
};

export const deleteInput = (stepNo, formId, inputName) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_INPUT,
    payload: {
      stepNo,
      formId,
      inputName,
    },
  });
};

export const toggleFormVisibility = (stepNo, formId) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_FORM_VISIBILITY,
    payload: {
      stepNo,
      formId,
    },
  });
};

export const setFormsOrder = (stepNo, startIndex, endIndex) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FORMS_ORDER,
    payload: {
      stepNo,
      startIndex,
      endIndex,
    },
  });
};

export const addForm = (stepNo, data) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_FORM,
    payload: {
      stepNo,
      data,
    },
  });
};

export const deleteForm = (stepNo, formId) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_FORM,
    payload: {
      stepNo,
      formId,
    },
  });
};

