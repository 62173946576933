import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getSelectSetForBoolean } from '../utils/formUtils';
import CustomInputSelect from '../components/CustomInputSelect';
import { convertSchemaTypeToInputType } from '../utils/formUtils';
import ButtonText from './ButtonText';

import {
  CustomInputContainer,
  CustomInputSubContainer,
  CustomInputLabel,
  CustomInputField,
  CustomInputCheckboxContainer,
  CustomInputCheckbox,
  CustomInputCheckboxCheckmark,
  CustomFileContainer,
  CustomFileSpan,
  CustomFileButton,
  CustomInputSelectDefault,
  FormErrMsg
} from '../styles/styled-components/CustomInput';
import ChangeIcon from '../../assets/svg/icons/change.svg';
import { Para14Thin } from '../styles/styled-components/GlobalFonts';
import TooltipInfo from './TooltipInfo';

const CustomInput = ({
  areCustomChildren=false,
  typeOfInput,
  checkbox=false,
  children,
  isFocus=false,
  isFullWith=false,
  isInputDisabled=false,
  isInvalid,
  isRequired=false,
  isRowElemDirection=false,
  isVisible=true,
  invalidMsg,
  max,
  min,
  name,
  handleBlur,
  handleChange,
  handleKeyDownEnter,
  label,
  placeholder,
  value,
  selectOptionsDef,
  selectOptions,
  extraWidth,
  tooltipInfo
}) => {

  const { t } = useTranslation();

  const renderInput = () => {
    if (typeOfInput === "select-def") {
      return (
        <CustomInputSelectDefault name={name} onChange={(e) => handleChange(e)} value={value} disabled={isInputDisabled || !isVisible} >
          {selectOptionsDef.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </CustomInputSelectDefault>
      );
    }

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleKeyDownEnter(e);
      }
    };

    if (typeOfInput === "select" || typeOfInput === "boolean") {
      if (typeOfInput === "boolean") {
        selectOptions = getSelectSetForBoolean();
      }
      return (
        <>
          <CustomInputSelect
            disabled={isInputDisabled || !isVisible}
            id={`${name}-input`}
            invalid={isInvalid}
            options={selectOptions} 
            value={value}
            onChangeFun={(e) => handleChange(e)} 
          />
          {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg>}
        </>
      );
    }

    if (typeOfInput === "file") {
      return (
        <>
          <CustomFileContainer invalid={isInvalid}>
            <input type="file" id={`${name}-file-input`} hidden />
            <CustomFileSpan fileExist={value ? 1 : 0} id="fileName">
              {value ? 
                t('template_definition.added_' + value)
                : 
                t('files.empty')
              }
            </CustomFileSpan>
            <CustomFileButton
              onClick={(e) => handleChange(e)}
            >
              {
                value ? (
                  <img src={ChangeIcon} alt="" />
                ) : (
                  t('files.choose_template')
                )
              }
            </CustomFileButton>
          </CustomFileContainer>
          {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg>}
        </>
      );
    }

    if (typeOfInput === "fileModal") {
      return (
        <div className='d-flex justify-content-between'>
          <Para14Thin className='d-flex align-items-center'>{value ? t('template_definition.added_' + value.resource_type) : ""}</Para14Thin>
          <ButtonText
            isKindWhite={true}
            text={value ? t('common.change') : t('common.add') }
            onClick={(e)=>handleChange(e)}
          /> 
        </div>
      );
    }

    if (typeOfInput === "script") {
      return (
        <>
          <CustomInputField
            id={`${name}-input`}
            invalid={isInvalid}
            name={name}
            placeholder={placeholder}
            type="textarea"
            value={value || ''}
            onBlur={handleBlur && ((e) => handleBlur(e))}
            onChange={(e) => handleChange(e)}
          />
          {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg>}
        </>
      );
    }
    const locTypeOfInput = convertSchemaTypeToInputType(typeOfInput);
    
    return (
      <>
        <CustomInputField
          autoFocus={isFocus}
          disabled={isInputDisabled || !isVisible}
          id={`${name}-input`}
          invalid={isInvalid}
          max={max}
          min={min}
          name={name}
          placeholder={placeholder}
          step={typeOfInput === "float" ? "0.01" : "1"}
          type={locTypeOfInput === "float" ? "text" : locTypeOfInput}
          value={value || ''}
          onBlur={handleBlur && ((e) => handleBlur(e))}
          checkbox={+checkbox}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e, (typeOfInput === "float"))}
        />
        {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg> }
      </>
    );
  };

  return (
    <CustomInputContainer $row_line={+isRowElemDirection} className={isFullWith && "w-100"} extrawidth={extraWidth ? 1 : 0}>
      <CustomInputLabel for={`${name}-input`}>
        {label}
        {tooltipInfo && <TooltipInfo text={tooltipInfo} elemName={name} />}
        {isRequired && " *"}
      </CustomInputLabel>
      <CustomInputSubContainer className={!checkbox && "flex-column"}>
        {checkbox && (
          <CustomInputCheckboxContainer>
            <CustomInputCheckbox
              type="checkbox"
              name={name}
              checked={!isInputDisabled}
              onChange={(e) => handleChange(e)}
              disabled={!isVisible}
            />
            <CustomInputCheckboxCheckmark />
          </CustomInputCheckboxContainer>
        )}
        {areCustomChildren 
          ? children
          : renderInput()
        }
      </CustomInputSubContainer>
    </CustomInputContainer>
  );
};

CustomInput.propTypes = {
  areCustomChildren: PropTypes.bool,
  typeOfInput: PropTypes.string,
  children: PropTypes.any,
  checkbox: PropTypes.bool,
  isFocus: PropTypes.bool,
  isFullWith: PropTypes.bool,
  isInputDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRowElemDirection: PropTypes.bool,
  isVisible: PropTypes.bool,
  id: PropTypes.string,
  invalidMsg: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleKeyDownEnter: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  selectOptionsDef: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    })
  ),
  complex: PropTypes.bool,
  extraWidth: PropTypes.bool,
  tooltipInfo: PropTypes.object
};

export default CustomInput;
