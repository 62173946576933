import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export const ModalStyle = styled(Modal)`
  max-width: 580px;

  .modal-content {
    border-radius: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 32px;
    padding-left: 32px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08), 0px 15px 35px -5px rgba(17, 24, 38, 0.15), 0px 0px 0px 1px rgba(152, 161, 178, 0.10);
  }
`;

export const ModalHeaderStyle = styled.div`
  border-bottom: 0;
  padding: 0;
  margin-bottom: 12px;
`;

export const ModalBodyStyle = styled(ModalBody)`
  padding: 0;
  padding-bottom: 16px;
`;

export const ModalFooterStyle = styled(ModalFooter)`
  padding-top: 26px;
  padding-right: 0;
  padding-bottom: 0;

  button:nth-of-type(2) {
    margin-left: 16px;
    margin-right: 0;
  }
`;