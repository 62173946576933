import styled, { css } from 'styled-components';

const boxGrey = css`
  background-color: ${props => props.theme.colors.gray.type_000};
  border-radius: 10px;
`;

export const BoxGrey20 = styled.div`
  ${boxGrey};
  padding: 20px;
`;

export const BoxGrey24 = styled.div`
  ${boxGrey};
  padding: 24px;
`;

export const HintsContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm}; //14px
  line-height: 20px;
  color: ${props => props.theme.colors.gray.type_700};
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  ul {
    padding-left: 24px;
  }
`;