import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';

import * as notify from '../../main/utils/notify';
import { validateSimpleValueByType } from '../utils/validationFunc';
import CustomModal from '../components/CustomModal';
import CustomInput from './CustomInput';

const TaskNameChanger = ({ isShowModal, modalTitle, taskCreateMode, taskName, setIsShowModal, handleConfirmTaskNameParam }) => {
  const { t } = useTranslation();
  const [ isInvalidTaskName, setIsInvalidTaskName ] = useState(false);
  const [ taskNameLoc, setTaskNameLoc ] = useState(taskName);

  const handleBlurName = (value) => {
    const isValid = validateSimpleValueByType(value, "stringTaskName");
    if (isValid) {
      setTaskNameLoc(value);
    } 
    setIsInvalidTaskName(!isValid);
  };  

  const handleChangeTaskName = (value) => {    
    setTaskNameLoc(value);
  };

  const handleConfirmTaskName = () => {
    if (isInvalidTaskName || !validateSimpleValueByType(taskNameLoc, "stringTaskName")) {
      notify.warning(t('validation.msg.form_invalid_bad_required_fields'));
    }
    else {
      handleConfirmTaskNameParam(taskNameLoc);
    }
  }; 

  return (
    <CustomModal 
      btnCancelText={t('common.cancel')}
      btnConfirmText={t('common.create')}
      showModal={isShowModal}
      additionalInfo={taskCreateMode === "upload" ? t("start_page.task_modal.uploadFile_additionalInfo") : ""}
      title={t('start_page.task_modal.'+modalTitle)}
      onClickCancelModal={() => setIsShowModal(false)}
      onClickConfirmModal={() => handleConfirmTaskName()}
    > 
      <Form>
        <CustomInput
          isFocus={true}
          typeOfInput="string"
          invalidMsg={t('start_page.task_modal.msg_invalid')}
          isFullWith={true}
          isInputDisabled={false}
          isRowElemDirection={false}
          isInvalid={isInvalidTaskName}
          isVisible={true}
          name="task_name"
          label={t('start_page.task_modal.input_name')}
          placeholder={t('start_page.task_modal.input_name')}
          value={taskNameLoc}
          handleBlur={(e) => handleBlurName(e.target.value)}
          handleChange={(e) => handleChangeTaskName(e.target.value)}
          handleKeyDownEnter={() => handleConfirmTaskName()}
        />
      </Form>
    </CustomModal>
  );
};

TaskNameChanger.propTypes = {
  isShowModal: PropTypes.bool, 
  modalTitle: PropTypes.string, 
  taskCreateMode: PropTypes.string, 
  taskName: PropTypes.string, 
  setIsShowModal: PropTypes.func, 
  handleConfirmTaskNameParam: PropTypes.func
};

export default TaskNameChanger;