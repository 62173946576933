import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, FormGroup } from 'reactstrap';

import CustomRadioButton from './CustomRadioButton';
import CustomInput from './CustomInput';
import CustomModal from './CustomModal';

const FileDefineModule = ( { fileData, isFileContentModal=true, isShowModal, onClickCancelModal, onClickConfirmModal }) => {
  const { t } = useTranslation();
  const [ typeOfDefinition, setTypeOfDefinition ] = useState(fileData?.resource_type || "path");
  const [ valuePath, setValuePath ] = useState(fileData?.path);
  const [ valueContent, setValueContent ] = useState(fileData?.content);
 
  useEffect(() => {
    if (fileData) {
      setTypeOfDefinition(fileData.resource_type);
      setValuePath(fileData?.path);
      setValueContent(fileData?.content);
    }
  }, [ fileData ]);

  const arrayRadio = [
    { id: "path", label: t((isFileContentModal ? 'template' : 'script') + '_definition.path'), inputType: "string" },
    { id: "content", label: t((isFileContentModal ? 'template' : 'script') + '_definition.content'), inputType: "script" }
  ];

  const handleChangeTypeOfDefinition = (event) => {
    setTypeOfDefinition((event.target.id).split('-')[1]);
  };

  const handleBlur = (value) => {
    //TODO walidacja
  };

  const handleChange = (value) => {
    if (typeOfDefinition === "path") {
      setValuePath(value);
    }
    else {
      setValueContent(value);
    }
  };

  const handleClickConfirmModal = () => {
    onClickConfirmModal((typeOfDefinition === "path" ? valuePath : valueContent), typeOfDefinition);
    setValueContent("");
    setValuePath("");
    setTypeOfDefinition("path");
  };

  const renderContent = () => {
    const selectedRadio = arrayRadio.find(radio => radio.id === typeOfDefinition);

    return (
      <CustomInput
        label={selectedRadio.label}
        //invalidMsg={t('start_page.task_modal.msg_invalid')}
        isFullWith={true}
        //isInvalid={isInvalidTaskName}
        name="file_path"
        placeholder=""
        typeOfInput={selectedRadio.inputType}
        value={typeOfDefinition === "path" ? valuePath : valueContent}
        handleBlur={(e) => handleBlur(e.target.value)}
        handleChange={(e) => handleChange(e.target.value)}
      />
    );
  };

  return (
    <CustomModal 
      btnCancelText={t('common.cancel')}
      btnConfirmText={t('common.add')}
      showModal={isShowModal}
      subTitle={t((isFileContentModal ? 'template' : 'script') +'_definition.select_kind_of_adding_option')}
      title={t((isFileContentModal ? 'template' : 'script') +'_definition.option')}
      onClickCancelModal={onClickCancelModal}
      onClickConfirmModal={handleClickConfirmModal}
    > 
      <Form>
        <FormGroup className='my-3'>
          {arrayRadio.map(item => {
            return <CustomRadioButton 
              className="mb-4"
              key={item.id}
              id={item.id} 
              label={item.label}
              checked={item.id === typeOfDefinition}
              onChange={handleChangeTypeOfDefinition}
            />;  
          })}
        </FormGroup>
        {renderContent()}

      </Form>
    </CustomModal>
  );
};

FileDefineModule.propTypes = {
  fileData: PropTypes.object,
  isFileContentModal: PropTypes.bool,
  isShowModal: PropTypes.bool, 
  onClickCancelModal: PropTypes.func, 
  onClickConfirmModal: PropTypes.func
};

export default FileDefineModule;