import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonStepImg,ButtonStepStyle } from '../styles/styled-components/StepsBar';
import iconStepCurrent from '../../assets/svg/icons/steps/step_current.svg';
import iconStepDefault from '../../assets/svg/icons/steps/step_default.svg';
import iconStepDone from '../../assets/svg/icons/steps/step_done.svg';
import iconStepDoneHover from '../../assets/svg/icons/steps/step_done_hover.svg';
import iconStepVisited from '../../assets/svg/icons/steps/step_visited.svg';

const ButtonStep = ({ isDisabled=false, state, stepName, onButtonClick, ...props }) => {
  const { t } = useTranslation();
  
  const getIconByState = (isHover) => {
    if (isHover) {
      return iconStepDoneHover;
    } 
    else {
      switch (state) {
      case "done":
        return iconStepDone;
      case "current":
        return iconStepCurrent;
      case "visited":
        return iconStepVisited;
      default:
        return iconStepDefault;
      }
    }
  };

  const [ btnIcon, setBtnIcon ] = useState(getIconByState(false));
  return ( 
    <ButtonStepStyle 
      isCurrent={state === "current"}
      disabled={isDisabled}
      onMouseOver={() => setBtnIcon(getIconByState(true))} 
      onMouseOut={() => setBtnIcon(getIconByState(false))}
      onClick={onButtonClick}
    >
      <ButtonStepImg 
        alt={t('step.button.'+state)}
        isCurrent={state === "current"}
        src={btnIcon} 
      />
    </ButtonStepStyle>
  );
};

ButtonStep.propTypes = {
  isDisabled: PropTypes.bool,
  state: PropTypes.string,
  stepName: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default ButtonStep;
