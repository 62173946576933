import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form, FormGroup } from 'reactstrap';

import TopBar from './topbar';
import { Para14, Para14Pink, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { ContainerInputSelect, FormErrMsg } from '../styles/styled-components/CustomInput';
import { BoxGrey24 } from '../styles/styled-components/GlobalStyle';
import { convertPropertiesToReduxPropertiesArray, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { convertAndCheckedValidOfValueToReduxSave, getCurrPropertyValidInfo, getSelectedSetFromEnum, 
  getSelectedSetFromObjectEntries } from '../utils/formUtils';
import ButtonALink from '../components/ButtonALink';
import { setSelectedDataExecution, updatePropertyExecution, updatePropertyExecutionQcgDataValue } from '../actions/executionActions';
import { getErrorValidMsg, checkIsPropValid, checkIsFormValid, isPropertyInInvalidArray } from '../utils/validationFunc';
import ErrorPage from '../components/ErrorPage';
import CustomRadioButton from '../components/CustomRadioButton';
import { CustomInputField } from '../styles/styled-components/CustomInput';
import CustomInputSelect from '../components/CustomInputSelect';
import { updatePropertyParallelAdvanced } from '../actions/executionActions';
import WalltimeUtils from '../../assets/contrib/walltime/walltime.ts';
import { convertReduxStepComplexToResultStep } from '../utils/resultUtils.js';
import { convertTimeToSek, convertTimeToSelectedUnit } from '../utils/dataUtils';
import RowTextTitleValue from '../components/RowTextTitleValue';

const StepExecution = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLang = navigator.language;

  const [ invalidProps, setInvalidProps ] = useState([]);
  const [ statusValidStep, setStatusValidStep ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ refreshWalltimeCalc, setRefreshWalltimeCalc ] = useState(true);

  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const schemaFormData = getStepFormData("Execution");
  const stateStepParameters = useSelector((state) => state.formsData.get(0));
  const stateStepMethod = useSelector((state) => state.method);
  const selectedExecution = useSelector((state) => state.execution.get('selectedOption'));
  const executionsProperties = useSelector((state) => state.execution.get('properties'));
  const selectedExecutionProps = useSelector((state) => state.execution.getIn([ 'properties', selectedExecution ]));
  const selectedExecutionQcgExecutionProps = useSelector((state) => state.execution.get('qcgProperties'));
  const selectedExecutionAdvancedProps = useSelector((state) => state.execution.get('advancedProps'));
  const requiredProps = schemaFormData[selectedExecution]?.required;
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const arrayExecutionTypes = getSelectedSetFromObjectEntries(schemaFormData);
  const contentData = getContentOfStep("execution");
  const initSingleEvaluationUnit = selectedExecutionAdvancedProps.find(property => property.get('name') === 'single_evaluation_unit').get('value');
  const [ singleEvaluationUnit, setSingleEvaluationUnit ] = useState({ label: initSingleEvaluationUnit, value: initSingleEvaluationUnit });
  
  const getValueOfStepMethodParams = useCallback((name) => {
    return stateStepMethod.getIn([ 'properties', stateStepMethod.get('selectedOption') ]).find(property => property.get('name') === name).get('value');
  }, [ stateStepMethod ]);

  const getElementOfAdvancedParallelPropByName = useCallback((name) => {
    return selectedExecutionAdvancedProps.find(property => property.get('name') === name);
  }, [ selectedExecutionAdvancedProps ]);

  const getValueOfAdvancedParallelPropByName = useCallback((name) => {
    return selectedExecutionAdvancedProps.find(property => property.get('name') === name).get('value');
  }, [ selectedExecutionAdvancedProps ]);

  const getValueOfExecutionPropByName = (name) => {
    return selectedExecutionProps.find(property => property.get('name') === name).get('value');
  };
  
  const getObjectExecutionQcgExecutionPropByName = useCallback((name) => {
    return selectedExecutionQcgExecutionProps.find(property => property.get('name') === name);
  }, [ selectedExecutionQcgExecutionProps ]);

  const getValueOfExecutionQcgExecutionPropByName = useCallback((name) => {
    return selectedExecutionQcgExecutionProps.find(property => property.get('name') === name).get('value');
  }, [ selectedExecutionQcgExecutionProps ]);
  
  const getElementOfExecutionQcgExecutionPropByName = (name) => {
    return selectedExecutionQcgExecutionProps.find(property => property.get('name') === name);
  };

  const calculateWalltime = useCallback(() => {
    if (invalidProps.length === 0) {
      if (!isLoading) {
        setIsLoading(true);
      }
      let result, polynomial, sparse;
      
      let n_params = 0;
      let stepParameters = convertReduxStepComplexToResultStep("sampled_params", stateStepParameters);
      stepParameters.forEach(param => {
        if (param.enabled && param.distribution.dist_type !== "none") {
          n_params += 1;
        }
      });
      
      const minutes_per_sample = convertTimeToSek(getValueOfAdvancedParallelPropByName('single_evaluation'), singleEvaluationUnit.label);
      switch (stateStepMethod.get("selectedOption")) {
      case "pce":
        polynomial = getValueOfStepMethodParams('polynomial_order');
        sparse = (getValueOfStepMethodParams('sparse').value === true);
        const regression = (getValueOfStepMethodParams('variant').value === 'regression');
        
        try {
          result = WalltimeUtils.estimatePceTime(n_params, polynomial, minutes_per_sample, sparse, regression);
        }
        catch (error) {
        }
        break;
      case "sc":
        polynomial = getValueOfStepMethodParams('polynomial_order');
        sparse = (getValueOfStepMethodParams('sparse').value === true);
        try {
          result = WalltimeUtils.estimateScTime(n_params, polynomial, minutes_per_sample, sparse);
        }
        catch (error) {
        }
        break;
      case "mc":
        const n_samples = getValueOfStepMethodParams('samples_num');
        const quasi = (getValueOfStepMethodParams('qmc').value === true);
        try {
          result = WalltimeUtils.estimateMcTime(n_params, n_samples, minutes_per_sample, quasi);
        }
        catch (error) {
        }
        break;
      default:
        break;
      }
      
      if (result) {
        dispatch(updatePropertyParallelAdvanced('execution_time_type', 'auto'));
        dispatch(updatePropertyParallelAdvanced('estimated_time', result.time));
        dispatch(updatePropertyParallelAdvanced('n_samples', result.n_samples));
        
        const execution_number_parallel_evaluations = getValueOfExecutionQcgExecutionPropByName("execution_number_parallel_evaluations");
        dispatch(updatePropertyExecutionQcgDataValue('execution_wallclock_time',
          Math.ceil(result.time / (isNaN(execution_number_parallel_evaluations) ? 1 : execution_number_parallel_evaluations))));
      }
      else {
        dispatch(updatePropertyParallelAdvanced('execution_time_type', 'manual'));
      }
      
      setIsLoading(false);
    }
  }, [ dispatch, getValueOfAdvancedParallelPropByName, getValueOfExecutionQcgExecutionPropByName, getValueOfStepMethodParams, 
    isLoading, stateStepMethod, stateStepParameters, invalidProps.length, singleEvaluationUnit.label ]);

  useEffect(() => {
    if (getValueOfAdvancedParallelPropByName('execution_time_type') === "auto") {
      calculateWalltime();
    }
  }, [ selectedExecution, refreshWalltimeCalc, calculateWalltime, getValueOfAdvancedParallelPropByName ]);

  const handleChangeExecutionType = (event) => {
    let currProps = executionsProperties.get(event.value);
    if (currProps.size === 0) {
      currProps = convertPropertiesToReduxPropertiesArray(schemaFormData[event.value].properties);
      let propQcgMaxVal = selectedExecutionQcgExecutionProps.find(property => property.get('name') === "execution_nodes").getIn([ "validInfo", "max" ]);
      currProps = currProps.setIn( [ currProps.findKey(item => item.get("name") === "nodes"), "validInfo", "max" ], propQcgMaxVal);
      
      propQcgMaxVal = selectedExecutionQcgExecutionProps.find(property => property.get('name') === "execution_cores_per_node").getIn([ "validInfo", "max" ]);
      currProps = currProps.setIn( [ currProps.findKey(item => item.get("name") === "cores"), "validInfo", "max" ], propQcgMaxVal);
    }
    dispatch(setSelectedDataExecution(event.value, currProps));
    setInvalidProps([]);
  };

  const handleTopbarBtnClick  = () => {
    checkIsFormValid(selectedExecution, requiredProps, selectedExecutionProps, setInvalidProps, setStatusValidStep, "execution");
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps)) {
      if (validInfo.type === "integer") {
        value = parseInt(value);
      }
      dispatch(updatePropertyExecution(selectedExecution, propertyName, value));
    }
  };  
  
  const handleChangePropValue = (event, propertyName, validInfo) => {
    const value = convertAndCheckedValidOfValueToReduxSave(event, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps);
    checkIsValidNumber(value, propertyName);
    dispatch(updatePropertyExecution(selectedExecution, propertyName, value));
  };

  const renderSchemaJsonFormData = () => {
    const formElem = schemaFormData[selectedExecution].properties;
    let currPropertyRedux, isInvalid, propValMin, propValMax;
    return (
      <>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedExecutionProps.find(property => property.get('name') === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          propValMin = currPropertyRedux.getIn([ 'validInfo', 'min' ]);
          propValMax = currPropertyRedux.getIn([ 'validInfo', 'max' ]);
          
          return (
            <CustomInput
              key={"key_"+item[0]}
              label={item[1].title}
              invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.get("value"), getCurrPropertyValidInfo(item[0], selectedExecutionProps)) : ""}
              isInvalid={isInvalid}
              isRequired={requiredProps.includes(item[0])}
              isRowElemDirection={true}
              min={propValMin}
              max={propValMax}
              name={item[0]}
              placeholder={item[1].placeholder || t('card.none')}
              selectOptions={item[1].enum ? getSelectedSetFromEnum(item[1].enum) : []}
              tooltipInfo={(propValMin || propValMax)
                ? <Trans i18nKey={t('step_execution.tooltip.range_available')}
                  values={{ min: (propValMin || "-"), max: (propValMax || "-") }}/>
                : undefined}
              typeOfInput={currPropertyRedux.getIn([ "validInfo", "type" ])}
              value={currPropertyRedux.get("value")}
              handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedExecutionProps))}
              handleChange={(e) => handleChangePropValue(e, item[0], getCurrPropertyValidInfo(item[0], selectedExecutionProps))}
            />
          );
        })}
      </>
    );
  };
  
  const checkIsValidNumber = (value, propertyName) => {
    value = parseInt(value);
    if (value < 1 || isNaN(value)) {
      setInvalidProps((prev) => ([ ...prev, propertyName ]));
    }
    else {
      if (isPropertyInInvalidArray(propertyName, invalidProps)) {
        setInvalidProps(invalidProps.filter(item => item !== propertyName));
      }
    }
  };
  
  const handleChangeAdvancedProp = (newValue, propertyName) => {
    if (propertyName === 'single_evaluation_unit') {
      const singleEvaluation = selectedExecutionAdvancedProps.find(property => property.get('name') === 'single_evaluation').get('value');
      const locNewValue = convertTimeToSelectedUnit(singleEvaluation, singleEvaluationUnit.label, newValue.label);
      if (locNewValue !== -1) {
        dispatch(updatePropertyParallelAdvanced('single_evaluation', locNewValue));
        dispatch(updatePropertyParallelAdvanced('single_evaluation_unit', newValue.label));
        setSingleEvaluationUnit(newValue);
      }
    }
    else {
      const propType = getElementOfAdvancedParallelPropByName(propertyName).get('validInfo').type;
      if (propType === "integer") {
        checkIsValidNumber(newValue, propertyName);
      }
      dispatch(updatePropertyParallelAdvanced(propertyName, newValue));
      setRefreshWalltimeCalc(status => !status);
    }
  };

  const handleChangeQcgProp = (value, propertyName) => {
    const propType = getElementOfExecutionQcgExecutionPropByName(propertyName).get('validInfo').type;
    if (propType === "integer") {
      checkIsValidNumber(value, propertyName);
    }
    
    dispatch(updatePropertyExecutionQcgDataValue(propertyName, value));
    setRefreshWalltimeCalc(status => !status);
  };

  const convertTimeToFormatHourAndMinutes = (time) => {
    let min;
    
    const hours = Math.floor(time / 3600);
    if (hours > 0) {
      min = Math.floor((time - hours * 3600) /60);
    }
    else {
      min = Math.floor(time / 60);
    }
    const sec = Math.ceil(time % 60);
    
    let retValue = "";
    if (hours > 0) {
      retValue += hours + "h ";
    }
    if (min > 0) {
      retValue += min +"min ";
    }
    if (sec > 0) {
      retValue += sec +"s ";
    }
    return retValue;
  };
  
  const showCoresHours = (isEntireExecution) => {
    const estimated_time = getValueOfAdvancedParallelPropByName('estimated_time');
    if (estimated_time === 0) {
      return 0;
    } 
    let coreHours = estimated_time;
    if (!isEntireExecution) {
      coreHours = coreHours / 1.5;
    }
    if (selectedExecution === "parallel") {
      coreHours *= getValueOfExecutionPropByName("nodes") * getValueOfExecutionPropByName("cores");
    }
    return convertTimeToFormatHourAndMinutes(coreHours);
  };

  const showWalltimeClock = () => {
    return convertTimeToFormatHourAndMinutes(getValueOfExecutionQcgExecutionPropByName('execution_wallclock_time'));
  };

  const renderInputElementByName = (name) => {
    const currElem = getObjectExecutionQcgExecutionPropByName(name);
    
    return (
      <CustomInput
        isRowElemDirection={true}
        label={t('step_execution.section_evaluation_parallel.number_parallel_evaluations')}
        name="number_parallel_evaluations"
        placeholder={currElem.get("placeholder")}
        typeOfInput="integer"
        isInvalid={isPropertyInInvalidArray(name, invalidProps)}
        invalidMsg={t('validation.msg.invalid_empty_value_or_below_min', { min: currElem.getIn([ 'validInfo', 'min' ]) })}
        min={currElem.getIn([ 'validInfo', 'min' ])}
        value={currElem.get("value")}
        handleChange={(e) => handleChangeQcgProp(e.target.value, name)}
      />
    );
  };
  
  const renderAdvancedFormContent = () => {
    const executionTimeType = getValueOfAdvancedParallelPropByName('execution_time_type');

    return (
      <>
        <hr className='my-3'/>
        <FormGroup>
          <Para14Pink className='mb-3'>{t('step_execution.section_evaluation_parallel.title')}</Para14Pink>
          {renderInputElementByName("execution_number_parallel_evaluations")}
        </FormGroup>
        <hr className='my-3'/>
        <FormGroup>
          <Para14Pink className='mb-3'>{t('step_execution.section_execution_time.title')}</Para14Pink>
          <CustomInput 
            className="mb-4"
            isRowElemDirection={true}
            label={t('step_execution.section_execution_time.execution_count')}
            name="execution_count"
            areCustomChildren={true}
          >
            <FormGroup className='d-flex'>
              <CustomRadioButton 
                className="mr-4 w-auto"
                key="auto"
                id="auto" 
                label={t('step_execution.section_execution_time.auto')}
                checked={executionTimeType === "auto"}
                onChange={() => handleChangeAdvancedProp("auto", 'execution_time_type')}
              />
              <CustomRadioButton 
                key="manual"
                id="manual" 
                label={t('step_execution.section_execution_time.manual')}
                checked={executionTimeType === "manual"}
                onChange={() => handleChangeAdvancedProp("manual", 'execution_time_type')}
              />
            </FormGroup>
          </CustomInput>
          <CustomInput 
            className="mb-4 "
            isRowElemDirection={true}
            label={t('step_execution.section_execution_time.'+ (executionTimeType === "auto" ? 'single_evaluation' : 'total_execution_time'))}
            name="single_evaluation"
            areCustomChildren={true}
          >
            <ContainerInputSelect className='d-flex'>
              <div className='w-75 mr-1'>
                <CustomInputField
                  className={(isPropertyInInvalidArray('single_evaluation', invalidProps) ? "is-invalid" : "")}
                  id="single_evaluation_input"
                  name="single_evaluation_input"
                  placeholder="Default 15 min"
                  type="number"
                  min={1}
                  step={1}
                  value={getValueOfAdvancedParallelPropByName('single_evaluation')}
                  onChange={(e) => handleChangeAdvancedProp(e.target.value, 'single_evaluation')}
                />
                {(isPropertyInInvalidArray('single_evaluation', invalidProps)
                  && <FormErrMsg className='mt-2 mb-n2'>{t('validation.msg.invalid_empty_value_or_below_min', { min: 1 })}</FormErrMsg>)}
              </div>
              <CustomInputSelect
                className="w-25"
                id="single_evaluation_unit"
                placeholder="min"
                options={[ { label: "s", value: "s" }, { label: "min", value: "min" }, { label: "h", value: "h" } ]}
                value={singleEvaluationUnit}
                onChangeFun={(e) => handleChangeAdvancedProp(e, 'single_evaluation_unit')}
              />
            </ContainerInputSelect>
          </CustomInput>
        </FormGroup>
        {(!isLoading && executionTimeType === "auto" && (invalidProps.length === 0))
          && <>
            <hr className='my-3'/>
            <div>
              <Para14Pink className='mb-3'>{t('step_execution.section_estimation_execution_time.title')}</Para14Pink>
              <RowTextTitleValue className="my-3"
                label={t('step_execution.section_estimation_execution_time.n_samples')}
                value={getValueOfAdvancedParallelPropByName('n_samples')}
              />
              <RowTextTitleValue className="my-3"
                label={t('step_execution.section_estimation_execution_time.cores_hours.evaluations')}
                value={showCoresHours(false)}
              />
              <RowTextTitleValue className="my-3"
                label={t('step_execution.section_estimation_execution_time.cores_hours.entire_execution')}
                value={showCoresHours(true)}
              />
              <RowTextTitleValue className="my-3"
                label={t('step_execution.section_estimation_execution_time.wallclock_time')}
                value={showWalltimeClock()}
              />
            </div>
          </>
        }
      </>     
    );
  };

  if (currentStepNo === -1) { //after refresh page
    return (
      <ErrorPage/> 
    );
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_execution.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick} statusValid={statusValidStep}/>
      <div>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_execution.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/execution/"}
            icon="documentation"         
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData?.description[currLang]}</Para14>
        <BoxGrey24>
          <Form>
            <FormGroup>
              <Para14Pink className='mb-3'>{t('step_execution.section_setting_single_model_evaluation.title')}</Para14Pink>
              <CustomInput
                typeOfInput="select"
                isRowElemDirection={true}
                isInputDisabled={false}
                isVisible={true}
                name="execution_type"
                label={t('step_execution.label_type')}
                placeholder=""
                selectOptions={arrayExecutionTypes}
                value={arrayExecutionTypes.find(type => type.value === selectedExecution)}
                handleChange={(e) => handleChangeExecutionType(e)}
              />
            </FormGroup>
            { selectedExecution === "parallel" && renderSchemaJsonFormData() }
            { renderAdvancedFormContent()}
          </Form>
        </BoxGrey24> 
      </div>
    </div>
  );
};

export default StepExecution;