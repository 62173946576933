

export const setSelectedDataExecution = (name, properties) => {
  return {
    type: 'SET_SELECTED_DATA_EXECUTION',
    payload: {
      name,
      properties
    }
  };
};

export const updatePropertyExecution = (selectedOption, propName, propValue) => {
  return {
    type: 'UPDATE_PROPERTY_EXECUTION',
    payload: {
      selectedOption,
      propName,
      propValue
    }
  };
}; 

export const setInitialExecutionQCGData = (properties) => {
  return {
    type: 'SET_INITIAL_EXECUTION_QCG_DATA',
    payload: properties
  };
};

export const updatePropertyExecutionQcgDataMaxValue = (name, value) => {
  return {
    type: 'UPDATE_PROPERTY_EXECUTION_QCG_DATA_MAX_VALUE',
    payload: {
      name,
      value
    }
  };
};

export const updatePropertyExecutionQcgDataValue = (name, value) => {
  return {
    type: 'UPDATE_PROPERTY_EXECUTION_QCG_DATA_VALUE',
    payload: {
      name, 
      value
    }
  };
}; 

export const setInitialAdvancedParallelProps = (properties) => {
  return {
    type: 'SET_INITIAL_ADVANCED_PARALLEL_PROPS',
    payload: properties
  };
};

export const updatePropertyParallelAdvanced = (name, value) => {
  return {
    type: 'UPDATE_PROPERTY_PARALLEL_ADVANCED',
    payload: {
      name, 
      value
    }
  };
};

export const resetExecution = {
  type: 'RESET_EXECUTION',
};