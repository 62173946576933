import { List } from 'immutable';

import { actionTypes } from '../actions/formsDataActions';

/**
  * @example 
  * // initialState structure explanation
  * 
  * List([                            // Array of all forms divided by steps
  *   List([                          // Array of all forms in a step
  *     Map({                         // Form data
  *       id: 0,                      // Form id
  *       isVisible: true,            // Is form visible
  *       inputsData: List([          // Array of inputs in a form
  *         Map({                     // Input data
  *           name: 'basicNumber',    // Input name
  *           value: 0                // Input value
  *         })
  *      ])
  *    })
  * ])
  * 
 */

const initialState = List([]);

// Utility functions
const getFormNo = (state, stepNo, formId) => {
  return state.getIn([ stepNo ]).findIndex((form) => form.id === formId);
};

const getInputNo = (state, stepNo, formNo, inputName) => {
  return state.getIn([ stepNo, formNo, 'inputsData' ]).findIndex((input) => input.name === inputName);
};

const getOptionNo = (state, stepNo, formNo, inputNo, optionName) => {
  return state.getIn([ stepNo, formNo, 'inputsData', inputNo, 'options' ]).findIndex((option) => option.name === optionName);
};

const getPropertyNo = (state, stepNo, formNo, inputNo, optionNo, propertyName) => {
  return state.getIn([ stepNo, formNo, 'inputsData', inputNo, 'options', optionNo, 'properties' ]).findIndex((property) => property.name === propertyName);
};

export const formsDataReducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.INIT_FORMS:
  {
    const { stepNo, forms } = action.payload;
    return state
      .setIn([ stepNo ], forms);
  }
  case actionTypes.SET_INPUT_DATA:
  {
    const { stepNo, formId, inputName, data } = action.payload;
    const formNo = getFormNo(state, stepNo, formId);
    const inputNo = getInputNo(state, stepNo, formNo, inputName);
    return state
      .setIn([ stepNo, formNo, 'inputsData', inputNo, 'value' ], data);
  }
  case actionTypes.SET_COMPLEX_INPUT_DATA:
  {
    const { stepNo, formId, inputName, optionName, propertyName, data } = action.payload;
    const formNo = getFormNo(state, stepNo, formId);
    const inputNo = getInputNo(state, stepNo, formNo, inputName);
    const optionNo = getOptionNo(state, stepNo, formNo, inputNo, optionName);
    const propertyNo = getPropertyNo(state, stepNo, formNo, inputNo, optionNo, propertyName);

    return state
      .setIn([ stepNo, formNo, 'inputsData', inputNo, 'options', optionNo, 'properties', propertyNo ], data);
  }
  case actionTypes.ADD_INPUT:
  {
    const { stepNo, formId, data } = action.payload;
    const formNo = getFormNo(state, stepNo, formId);
    return state
      .updateIn([ stepNo, formNo, 'inputsData' ], (inputsData) => [ ...inputsData, data ]);
  }
  case actionTypes.DELETE_INPUT:
  {
    const { stepNo, formId, inputName } = action.payload;
    const formNo = getFormNo(state, stepNo, formId);
    const inputNo = getInputNo(state, stepNo, formNo, inputName);
    return state
      .deleteIn([ stepNo, formNo, 'inputsData', inputNo ]);
  }
  case actionTypes.TOGGLE_FORM_VISIBILITY:
  {
    const { stepNo, formId } = action.payload;
    const formNo = getFormNo(state, stepNo, formId);
    return state
      .setIn([ stepNo, formNo, 'isVisible' ], !state.getIn([ stepNo, formNo, 'isVisible' ]));
  }
  case actionTypes.SET_FORMS_ORDER:
  {
    const { stepNo, startIndex, endIndex } = action.payload;
    const result = Array.from(state.getIn([ stepNo ]));
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return state
      .setIn([ stepNo ], List(result));
  }
  case actionTypes.ADD_FORM:
  {
    const { stepNo, data } = action.payload;
    return state
      .updateIn([ stepNo ], (forms) => [ ...forms, data ]);
  }
  case actionTypes.DELETE_FORM:
  {
    const { stepNo, formId } = action.payload;
    const formNo = getFormNo(state, stepNo, formId);
    return state
      .deleteIn([ stepNo, formNo ]);
  }
  default:
    return state;
  }  
};