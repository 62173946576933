import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { resetStep } from '../actions/stepsActions';
import ButtonText from '../components/ButtonText';
import { TitleH3 } from '../styles/styled-components/GlobalFonts';

const SummaryView = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnClickReset = () => {
    dispatch(resetStep);
    history.push({ pathname: '/' });
  };

  return (
    <div>
      <Helmet>
        <title>summary-view</title>
      </Helmet>
      <div className='d-flex justify-content-between'>
        <TitleH3>Podsumowanie zdefiniowanych danych wejściowych</TitleH3>
        <ButtonText 
          isKindWhite={true} 
          onClick={handleOnClickReset}
          text={t('button.back_to_home_page')}
        />
      </div>
      <div className='my-4'>
        <pre id="json">{JSON.stringify(location.state.resultJson, null, 2)}</pre>
      </div>
    </div>
  );
};

export default SummaryView;
