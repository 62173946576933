import i18n from '../../main/utils/i18nConfigProvider';
import * as notify from '../../main/utils/notify';

//const patternInteger = /^[0-9]+$/;
const patternString = /^[\w./ \-_]+$/;
const patternStringWithSpace = /^\w+( )*$/;
const patternFileName=/^[\w\d-]+$/;
//patternStringSeparateComma > a,b
const patternStringSeparateComma = /^([\w./\-()]+((,){1}( )?[\w./\-()]+)*)+$/;
//patetrnArrayInArrayWithString > a,b,[c,d],e
const patetrnArrayInArrayWithString 
  = /^((\[{1}(\w+((,){1}( )?\w+)*)+\]{1})|(\w+((,){1}( )?\w+)*))+(((,){1}( )?)((\[{1}(\w+((,){1}( )?\w+)*)+\]{1})|(\w+((,){1}( )?\w+)*)))*$/;

const getAnyTypeValues = (anyTypeArray) => {
  return Array.prototype.map.call(anyTypeArray, function(item) { return item.type+"-"+item.items.type+"-"+(item.items?.items?.type || ""); });
};

export const isPropertyInInvalidArray = (name, invalidArray) => {
  if (!invalidArray?.length) {
    return false;
  }
  return invalidArray.find(property => property === name) !== undefined;
};

export const validateSimpleValueByType = (value, validType) => {
  if (validType === "stringTaskName") {
    return patternStringWithSpace.test(value);
  }
  if (validType === "fileName") {
    return patternFileName.test(value);
  }
};

export const validateValueByType = (value, validInfo) => {
  let isRegexPass;
  if (validInfo.type === "select" || validInfo.type === "boolean" || validInfo.type === "select-one") {
    return value !== ""; 
  }

  if (validInfo.type === "integer") {
    let valueInt = parseInt(value);
    if (validInfo.min) {
      return valueInt >= validInfo.min;
    }
    else {
      return !Number.isNaN(valueInt);
    }
  }

  if  (validInfo.type === "number") {
    const patternNumber = /^[+-]?([0-9]+\.[0-9]*|[0-9]*\.[0-9]+|[0-9]+)([eE][+-]?[0-9]+)?$/;
    const isValidNumber = new RegExp(patternNumber).test(value);
    
    if (isValidNumber) {
      if (validInfo.min) {
        return value >= validInfo.min;
      }
      else {
        return (value !== "" && !Number.isNaN(value));
      }
    }
    return isValidNumber;
  }

  if (validInfo.type === "string" || validInfo.type === "text") {
    if (validInfo.pattern) {
      return (new RegExp(validInfo.pattern)).test(value);
    }
    else {
      return patternString.test(value);
    }
  }

  if (validInfo.type === "array") {
    if (validInfo.items.type === "string" || validInfo.items.type === "text") {
      isRegexPass = patternStringSeparateComma.test(value);
      return isRegexPass;
    }
  }

  if (validInfo.anyOf) {
    let arrayIsValidRegex=[];
    getAnyTypeValues(validInfo.anyOf).forEach(validElem => {
      if (validElem === "array-string-") {
        arrayIsValidRegex.push(patternStringSeparateComma.test(value));
      }
      else if (validElem === "array-array-string") {
        arrayIsValidRegex.push(patetrnArrayInArrayWithString.test(value));
      }
    });

    return arrayIsValidRegex.includes(true);
  }
};

const getNotifyOfEmptyValue = (value, msgFilled) => {
  if (value) {
    return i18n.t('validation.error') + i18n.t('validation.msg.' + msgFilled);   
  }
  else {
    return i18n.t('validation.error') + i18n.t('validation.msg.invalid_empty_value');   
  }
};

export const getErrorValidMsg = (value, validInfo) => {
  if (validInfo.type === "array") {
    if (validInfo.items.type === "string") {
      return getNotifyOfEmptyValue(value, 'invalid_array_string');
    }
  }
  if (validInfo.anyOf) {
    const validTypes = getAnyTypeValues(validInfo.anyOf);
    if (validTypes.includes("array-array-string")) {
      return i18n.t('validation.error') + i18n.t('validation.msg.invalid_array_array_string');  
    }
    if (validTypes.includes("array-string-")) {
      return i18n.t('validation.error') + i18n.t('validation.msg.invalid_array_string');  
    }
  }
  if (validInfo.type === "integer") {
    if (+value < validInfo.min) { //+value - convert to int
      return i18n.t('validation.error') + i18n.t('validation.msg.invalid_empty_value_or_below_min', { min: validInfo.min });
    }
  }
 
  //for type as integer/string
  return getNotifyOfEmptyValue(value, 'invalid_'+validInfo.type);  
} ;

export const checkIsPropValid = (value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps) => {
  const isValidProperty = validateValueByType(value, validInfo);
  const isPropertyInArrayOfInvalidProps = isPropertyInInvalidArray(propertyName, invalidProps);

  if (value === "" && !requiredProps.includes(propertyName)) {
    return true;
  }
  if (isValidProperty) {
    if (isPropertyInArrayOfInvalidProps) {
      setInvalidProps(invalidProps.filter(item => item !== propertyName));
    }
    return true;
  }
  if (!isValidProperty && !isPropertyInArrayOfInvalidProps) {
    setInvalidProps(data => [ ...data, propertyName ]);
  }
  return false;
};

export const checkIsPropValidCustom = (value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps, formId) => {

  const onlyExists = [ "template", "encoder" ];

  const isValidProperty = onlyExists.includes(propertyName) ?
    !!value
    :
    validateValueByType(value, validInfo);
  const isPropertyInArrayOfInvalidProps = isPropertyInInvalidArray(propertyName, invalidProps[formId]);

  const addProperty = () => {

    const createArray = (prev) => {
      if (prev[formId]) {
        return [
          ...prev[formId],
          propertyName
        ];
      } 
      else {
        return [
          propertyName
        ];
      }
    };

    setInvalidProps((prev) => ({
      ...prev,
      [formId]: createArray(prev)
    }));
  };

  if (value === "" && requiredProps && !isPropertyInArrayOfInvalidProps) {
    addProperty();
    return true;
  }

  if (isValidProperty) {
    if (isPropertyInArrayOfInvalidProps) {
      const dataWithoutProperty = invalidProps[formId].filter(item => item !== propertyName);
      setInvalidProps((prev) => ({
        ...prev,
        [formId]: dataWithoutProperty
      }));
    }
  }
  if (!isValidProperty && !isPropertyInArrayOfInvalidProps) {
    addProperty();
    return true;
  }
  return false;
};

export const checkIsFormValid  = (selectedType, requiredProps, selectedTypeProps, setInvalidProps, setStatusValidStep, stepName) => {
  let isFormOk = true;
  if (!selectedType) {
    isFormOk = false;
    notify.warning(i18n.t('validation.msg.form_invalid_no_select.'+stepName));
  }
  else { 
    let isRequiredProp;
    let locInvalidProps = [];

    selectedTypeProps.forEach(prop => {
      isRequiredProp = (requiredProps.find(elem => elem === prop.get("name")) !== undefined);
      //empty and not required value => ok
      if (!prop.get("value") && !isRequiredProp) { 
        return;
      }

      //empty array or anyOf array and not required value => ok
      if ((prop.get("validInfo").type === "array" || prop.get("validInfo").anyOf) && prop.get("value")?.length === 0  && !isRequiredProp) { 
        return;
      }

      if (prop.get("validInfo").type === "fileModal") {
        return;
      }

      //empty and required => zle
      if (!prop.get("value") && isRequiredProp) { 
        isFormOk = false;
        locInvalidProps.push(prop.get("name"));
        return;
      }

      //bad valid (and required or not) => zle
      if (!validateValueByType(prop.get("value"), prop.get("validInfo"))) {
        isFormOk = false;
        locInvalidProps.push(prop.get("name"));
        return;
      }
    });

    if (!isFormOk) {
      setInvalidProps(locInvalidProps);
      notify.warning(i18n.t('validation.msg.form_invalid_bad_required_fields'));
    }
  }
  if (isFormOk) {
    setStatusValidStep(true);
  }
};