import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import NotFound from '../components/notFound';
import '../../uqsa-ui/styles/index.scss';
import Home from '../../uqsa-ui/containers/Home';
import StepParameters from '../../uqsa-ui/containers/StepParameters';
import StepMethod from '../../uqsa-ui/containers/StepMethod';
import StepEncoder from '../../uqsa-ui/containers/StepEncoder';
import StepDecoder from '../../uqsa-ui/containers/StepDecoder';
import StepApplication from '../../uqsa-ui/containers/StepApplication';
import StepExecution from '../../uqsa-ui/containers/StepExecution';
import SummaryView from '../../uqsa-ui/containers/SummaryView';

@connect(
  state => ({
    location: state.router.location
  })
)
export default class Root extends Component {
  
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      toastr.removeByType('error');
    }
  }

  render() {
    if (this.props.location.pathname === "/") {
      return <Route exact strict path='/' component={Home} />;
    }
  
    return (
      <Container className="my-2">
        <Switch>
          <Route exact strict path='/parameters' component={StepParameters} />
          <Route exact strict path='/method' component={StepMethod} />
          <Route exact strict path='/encoder' component={StepEncoder} />
          <Route exact strict path='/decoder' component={StepDecoder} />
          <Route exact strict path='/application' component={StepApplication} />
          <Route exact strict path='/execution' component={StepExecution} />

          <Route exact strict path='/summary' component={SummaryView} />


          <Route component={NotFound} />
        </Switch>
      </Container>
    );
  }
}

Root.propTypes = {
  location: PropTypes.object
};
