import { useSelector, useDispatch } from 'react-redux';
import { resetFormDataParametersEncoder } from '../utils/resetFormData';

const useApiFormData = (step = -1) => {

  const dispatch = useDispatch();
  const stepsData = useSelector((state) => state.steps.get('stepsData'));
  const formsData = useSelector((state) => state.formsData.toJS());

  if (formsData.length !== 0 && step === -1) {
    return;
  }
  
  return resetFormDataParametersEncoder(dispatch, stepsData, step);
};

export default useApiFormData;