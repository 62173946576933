import { List, Map } from 'immutable';

import schemaJSON from '../../assets/config/schema/muqsa_json_schema.json';
import schemaJSON_QCG from '../../assets/config/schema/qcg_muqsa_json_schema.json';
import contentJSON from '../../assets/config/data/content.json';


import jsonInfo from '../../assets/config/data/info.json';
import { setLinkDoc } from '../actions/generalReducer';
import { convertTimeToMaxUnit } from './dataUtils';

export const initGeneralDataFromJsonConfig = (dispatch) => {
  const schemaObj = JSON.parse(JSON.stringify(jsonInfo));
  dispatch(setLinkDoc(schemaObj.link_doc));
  return schemaObj.link_doc;
};

const getFormData = (nodeName, schemaObj) => {
  let result = {};
  let dataNodePath;
  let listDataElem = schemaObj?.definitions[nodeName]?.discriminator.mapping;

  if (!listDataElem) {
    return;
  }

  Object.entries(listDataElem).forEach(item => {
    dataNodePath = item[1].split("/").slice(1);    
    result[item[0]] = schemaObj[dataNodePath[0]][dataNodePath[1]];
    delete result[item[0]].properties[nodeName.toLowerCase()+"_type"];
    result[item[0]].required = result[item[0]].required.filter(propName => propName !== nodeName.toLowerCase()+"_type");
  });
  return result;
};

export const getStepFormData = (stepName) => {
  let schemaObj = JSON.parse(JSON.stringify(schemaJSON));
  let result = {};

  if (stepName === "Parameters") {
    result = schemaObj.definitions["SampledParam"];

    let dataNodePath;
    let dist = result.properties.distribution.$ref.split("/").slice(1);
    result.properties.distribution = getFormData(dist[1], schemaObj);

    let ref_of_sampling_space = result.properties.sampling_space;
    result.properties.sampling_space = {};

    Object.entries(ref_of_sampling_space.discriminator.mapping).forEach(item => {
      dataNodePath = item[1].split("/").slice(1);
      result.properties.sampling_space[item[0]] = schemaObj[dataNodePath[0]][dataNodePath[1]];
    });
  }
  else if (stepName === "Encoder") {
    result.title = schemaObj.definitions["Encoder"].title;
    let dataNodePath;
    result.properties = {};
    result.properties.discriminator = {};

    Object.entries(schemaObj.definitions["Encoder"].discriminator.mapping).forEach(item => {
      dataNodePath = item[1].split("/").slice(1);
      result.properties.discriminator[item[0]] = schemaObj[dataNodePath[0]][dataNodePath[1]];
      if (result.properties.discriminator[item[0]]?.properties?.template) {
        result.properties.discriminator[item[0]].properties.template.type = "file";
      }
      if (result.properties.discriminator[item[0]]?.properties?.encoder) {
        result.properties.discriminator[item[0]].properties.encoder.type = "file";
      }
    });
  }
  else {  
    result = getFormData(stepName, schemaObj);
  }
  return result;
};

export const getQCGProperties = () => {
  let schemaObj = JSON.parse(JSON.stringify(schemaJSON_QCG));
  let listProps = List();
  let singleProp;

  let schemaProps = schemaObj?.definitions.QCGmUQSA.properties;
  Object.entries(schemaProps).forEach(property => {
    if (property[0].startsWith("execution_")) {
      singleProp = setSingleProp(property[0], (property[1].default ?? ""), property[1].type, (property[1].required ?? false));
      singleProp.title = property[1].title;
      singleProp.placeholder = property[1].placeholder;
      singleProp.step = property[1].step ?? "";

      if ("exclusiveMinimum" in property[1]) {
        singleProp.validInfo["min"] = property[1].exclusiveMinimum + 1;
      }
      listProps = listProps.push(Map(singleProp));
    }
  });
  return listProps;  
};

const setSingleProp = (name, value, type, isRequired) => {
  let singleProp = {};
  singleProp.name = name;
  singleProp.value = value;
  singleProp.validInfo = {};
  singleProp.validInfo.type = type;
  singleProp.validInfo.required = isRequired;
  return singleProp;
};

export const setExecutionAdvancedParallelProps = () => {
  let listProps = List();
  listProps = listProps.push(Map(setSingleProp("execution_time_type", "auto", "string", false)));
  listProps = listProps.push(Map(setSingleProp("single_evaluation", 15, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("single_evaluation_unit", "min", "string", false)));
  listProps = listProps.push(Map(setSingleProp("n_samples", 0, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("estimated_time", 0, "integer", false)));
  return listProps;  
};

export const setExecutionAdvancedParallelPropsWithUploadData = (uploadData) => {
  let listProps = List();
  listProps = listProps.push(Map(setSingleProp("execution_time_type", uploadData.execution_time_type, "string", false)));
  
  const singleEvaluationMaxUnit = convertTimeToMaxUnit(uploadData.single_evaluation);
  listProps = listProps.push(Map(setSingleProp("single_evaluation", singleEvaluationMaxUnit.value, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("single_evaluation_unit", singleEvaluationMaxUnit.unit, "string", false)));
  
  listProps = listProps.push(Map(setSingleProp("n_samples", 0, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("estimated_time", 0, "integer", false)));
  return listProps;
};

export const getQCGProperty = (name) => {
  const schemaObj = JSON.parse(JSON.stringify(schemaJSON_QCG));
  let schemaProps = schemaObj?.definitions.QCGmUQSA.properties;
  return schemaProps[name].default;
};

export const convertPropertiesToReduxPropertiesArray = (properties) => {
  let listProps = List();
  let singleProp;
  Object.entries(properties).forEach(property => {
    singleProp = {};
    singleProp.name = property[0];
    singleProp.value = "";
    singleProp.validInfo = {};
    singleProp.validInfo.type = (property[1].enum ? "select" : property[1].type);
    if (property[1].pattern) {
      singleProp.validInfo.pattern = property[1].pattern;
    }
    Object.entries(property[1]).forEach(item => {
      switch (item[0]) {
      case "default":
        if (property[1].enum || property[1].type === "boolean") { //or enum
          singleProp.value = {
            "label": item[1].toString(),
            "value": item[1]
          };
        }
        else {
          singleProp.value = item[1];
        }
        break;
      case "enum":
      case "type":
      case "title":
        break;
      case "anyOf":
        singleProp.validInfo.anyOf = item[1];
        break;
      case "exclusiveMinimum":
        singleProp.validInfo.min = item[1] + 1;
        break;
      case "items":
        singleProp.validInfo[item[0]] = item[1];
        break;
      case "discriminator": 
        singleProp.validInfo.type = "fileModal";
        break;
      case "placeholder": 
        singleProp.placeholder = item[1];
        break;
      default:
        singleProp[item[0]] = item[1];
        break;
      }

    });
    listProps = listProps.push(Map(singleProp));

  });
  return listProps;
};

export const getContentOfStep = (stepName) => {
  const contentData = JSON.parse(JSON.stringify(contentJSON));
  return contentData.steps[stepName];
};