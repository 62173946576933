import { convertStringToBase64 } from './formUtils';
import { getQCGProperty } from './jsonSchemaParser';
import { convertTimeToSek } from './dataUtils';

const convertReduxStepToResultStep = (stepName, reduxStepData) => {
  let stepResult = {};
  stepResult[stepName + "_type"] = reduxStepData.get("selectedOption");
  if (stepName === "execution") {
    let idx;
    idx = reduxStepData.get("qcgProperties").findKey(property => property.get('name') === 'execution_number_parallel_evaluations');
    stepResult['execution_number_parallel_evaluations'] = reduxStepData.getIn([ 'qcgProperties', idx, 'value' ]);
    
    idx = reduxStepData.get("advancedProps").findKey(property => property.get('name') === 'execution_time_type');
    stepResult['execution_time_type'] = reduxStepData.getIn([ 'advancedProps', idx, 'value' ]);
    
    const singleEvaluationVal = reduxStepData.get("advancedProps").find(property => property.get('name') === 'single_evaluation').get('value');
    const singleEvaluationUnit = reduxStepData.get("advancedProps").find(property => property.get('name') === 'single_evaluation_unit').get('value');
    stepResult['single_evaluation'] = convertTimeToSek(singleEvaluationVal, singleEvaluationUnit);
    
    if (reduxStepData.get("selectedOption") === "serial") {
      return stepResult;
    }
  }
  reduxStepData.getIn([ "properties", reduxStepData.get("selectedOption") ]).forEach(property => {
    stepResult[property.get("name")] = convertValueToSaveFormat(property.get("value"), property.get("validInfo"));
  });
  return stepResult;
};

export const convertReduxStepComplexToResultStep = (name, stateFormData) => {
  let stepResult = [];
  let propElem;
  let optionProperties;
  let selectedPropsData;
  let optionData;

  stateFormData.forEach(card => {
    propElem = {};
    card.inputsData.forEach(inputElem => {
      if (inputElem.fieldType === "basic") {
        propElem[inputElem.name] = inputElem.value;
      }
      else if (inputElem.fieldType === "complex") {
        selectedPropsData = {};
        optionProperties = inputElem.options[inputElem.value].properties;
        for (let index = 1; index < optionProperties.length; index++) {
          optionData = optionProperties[index];
          if (!optionData.required) {
            selectedPropsData[`disabled_${optionData.name}`] = optionData.disabled;
          }
          if (!optionData.disabled) {
            selectedPropsData[optionData.name] = optionData.value;
          }
        }

        if (name === "encoder") {
          selectedPropsData.encoder_type = inputElem.options[inputElem.value].name;
        }
        if (name === "sampled_params") {
          if (inputElem.name === "distribution") {
            selectedPropsData.dist_type = inputElem.options[inputElem.value].name;
          }
          if (inputElem.name === "sampling_space") {
            selectedPropsData.param_type = inputElem.options[inputElem.value].name;
          }
        }

        if (name === "sampled_params") {
          propElem[inputElem.name] = selectedPropsData;
        }
        else {
          propElem = selectedPropsData;
        }
      }
    });
  
    if (name === "sampled_params") {
      propElem.enabled = card.isVisible;
    }
    stepResult.push(propElem);
  });

  return stepResult;
};

export const convertValueToSaveFormat = (value, validInfo ) => {
  let result = value; //integer, string, number (float)
  if (validInfo.type === "integer") {
    return parseInt(value);
  }
  if ([ "boolean", "select" ].includes(validInfo.type)) { //boolean/select (object)
    return value.value;
  }
  if (validInfo.type === "array") {
    if (value) {
      result = value.split(",");  
    }
    else {
      result = [];
    }
  }
  if (validInfo.type === "fileModal" && value.resource_type === "content") {
    return { resource_type: value.resource_type, content: convertStringToBase64(value.content) };
  }
  if (validInfo.anyOf) { //array[string]/array[array[string]]
    if (value.includes("[")) {
      const regex = /(\[[^([\])]+\])/;
      let newArray, arrayValues;
      result = [];

      value.split(regex).forEach(item => {
        if (item.includes("[")) {
          newArray = [];

          arrayValues = item.substring(1,item.length-1);
          arrayValues.split(",").forEach(elem => {
            if (elem && elem !== " ") {
              newArray.push(elem);
            }
          });
          result.push(newArray);
        } 
        else {
          item.split(",").forEach(elem => {
            if (elem && elem !== " ") {
              result.push(elem);
            }
          });
        }
      });
    }
    else {
      result = value.split(",");
    }
  }
  return result;
};

export const copyValueFromExecutionProperties = (qcgParamName, muqsaParamName, stateDataExecution) => {
  let muqsaElement = stateDataExecution.get("properties").find(property => property.get('name') === muqsaParamName);
  if (muqsaElement) {
    return muqsaElement.get("value");
  } 
  else {
    return getQCGProperty(qcgParamName);
  }
};

export const generateResultFromRedux = (stateData) => {
  let result;
  
  let qcgParams = {};
  let tmpValue, currPropName;
  qcgParams.job_name = stateData.general.get("taskName");
  stateData.execution.get("qcgProperties").forEach(property => {
    tmpValue = "";
    currPropName = property.get("name");
    if (currPropName === "execution_nodes") {
      tmpValue = copyValueFromExecutionProperties("execution_nodes", "nodes", stateData.execution);
    }
    else if (currPropName === "execution_cores_per_node") {
      tmpValue = copyValueFromExecutionProperties("execution_cores_per_node", "cores", stateData.execution);
    }
    if (!tmpValue) {
      tmpValue = convertValueToSaveFormat(property.get("value"), property.get("validInfo"));
    }
    qcgParams[currPropName] = tmpValue;
  });
  
  result = qcgParams;

  let res_muqsa = {};
  res_muqsa.name = stateData.general.get("taskName");
  res_muqsa.version = "122";
  res_muqsa.executed = "false";

  res_muqsa.sampled_params = convertReduxStepComplexToResultStep("sampled_params", stateData.formsData.get(0));
  res_muqsa.method = convertReduxStepToResultStep("method", stateData.method);
  res_muqsa.encoder = convertReduxStepComplexToResultStep("encoder", stateData.formsData.get(2));
  res_muqsa.decoder = convertReduxStepToResultStep("decoder", stateData.decoder);
  res_muqsa.application = convertReduxStepToResultStep("application", stateData.application);
  res_muqsa.execution = convertReduxStepToResultStep("execution", stateData.execution);
  result.input_muqsa_config = res_muqsa;
  return result;
};