import React from 'react';
import PropTypes from 'prop-types';

import { ButtonTextWhiteStyle } from '../styles/styled-components/Button';
import IconSave from '../../assets/svg/icons/common/save.svg';

const ButtonIconTypes = {
  "save": { icon: IconSave }
};

const ButtonIconText = ({ 
  className,
  icon,
  iconWidth="16px",
  text,
  onClick
}) => {

  return (
    <ButtonTextWhiteStyle className={className} onClick={onClick} type="button">
      <img 
        alt=""
        className='icon' 
        src={ButtonIconTypes[icon].icon} 
        width={iconWidth}/>
      <span className='text'>{text}</span>
    </ButtonTextWhiteStyle>
  );
};

ButtonIconText.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf([ 'save' ]),
  iconWidth: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
};

export default ButtonIconText;  