import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import StepsBar from '../components/StepsBar';
import ButtonText from '../components/ButtonText';
import { resetStep, setLastStep, setNextStep } from '../actions/stepsActions';
import { Para14, TitleH1 } from '../styles/styled-components/GlobalFonts';
import { generateResultFromRedux } from '../utils/resultUtils';
import * as libQCG from '../lib/qcgFormToolbox';
import { saveDataToFile } from '../utils/fileUtils';
import ButtonIcon from '../components/ButtonIcon';
import TaskNameChanger from '../components/TaskNameChanger';
import { setTaskName } from '../actions/generalReducer';
import ButtonIconText from '../components/ButtonIconText';

const TopBar = ({ onClickTopbarBtn, statusValid }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const clickedBtnName = useRef();
  const clickedBtnGiveTheTask = useRef();
  const [ isShowModalEditTaskName, setIsShowModalEditTaskName ] = useState(false);

  const stepCurrent = useSelector((state) => state.steps.get('stepCurrent'));
  const stepsData = useSelector((state) => state.steps.get('stepsData'));
  const stateData = useSelector((state) => state);
  const taskName = useSelector((state) => state.general.get("taskName"));

  const sendUserTaskData = useCallback(() => {
    const result = generateResultFromRedux(stateData);
    libQCG.sendMessage(result);

    // alert("Zadanie zostało zlecone.");
    // history.push({ pathname: '/summary', state: { resultJson: result } });
    //}, [ history, stateData ]);
  }, [ stateData ]);

  const handleNextActionAfterConfirmValidCurrentForm = useCallback(() => {
    if (stepCurrent < (stepsData.length - 1)) {
      dispatch(setNextStep);
      history.push({ pathname: '/' + stepsData[stepCurrent + 1].name });
    }
    else if (clickedBtnName.current === "next") {
      if (stepCurrent < (stepsData.length - 1)) {
        dispatch(setNextStep);
        history.push({ pathname: '/' + stepsData[stepCurrent + 1].name });
      }
      else {
        clickedBtnGiveTheTask.current = true;
        if (stateData.execution.get("selectedOption")) {
          sendUserTaskData();
        }
      }
    }
  }, [ dispatch, history, stepCurrent, stepsData, sendUserTaskData, stateData.execution ]);

  useEffect(() => {
    if (statusValid) {
      handleNextActionAfterConfirmValidCurrentForm();
    }
  }, [ statusValid, handleNextActionAfterConfirmValidCurrentForm ]);

  useEffect(() => {
    if (stateData.execution.get("name") && clickedBtnGiveTheTask.current) {
      sendUserTaskData();
    }
  }, [ stateData, sendUserTaskData ]);

  const handleOnBtnClick = (btnName) => {
    clickedBtnName.current = btnName;
    if (btnName === "last") {
      dispatch(setLastStep);
      history.push({ pathname: '/' + stepsData[stepCurrent - 1].name });
    }
    else {
      onClickTopbarBtn();
    }
  };

  const handleOnClickReset = () => {
    dispatch(resetStep);
    history.push({ pathname: '/' });
  };

  const handleConfirmSaveFileName = () => {
    const result = generateResultFromRedux(stateData);
    saveDataToFile(JSON.stringify(result, null, 2), taskName+".json");
  };

  const handleConfirmTaskName = (taskName) => {
    dispatch(setTaskName(taskName));
    setIsShowModalEditTaskName(false);
  };

  const renderContent = () => {
    if (history.location.pathname !== "/") {
      return (
        <>
          {stepCurrent > -1 
            ? <>
              <div className='d-flex justify-content-between my-4'>
                { stepCurrent === 0 
                  ? <ButtonText 
                    isKindWhite={true} 
                    onClick={handleOnClickReset}
                    text={t('button.back_to_home_page')}
                  />
                  : <ButtonText 
                    className="mx-2" 
                    isKindWhite={true} 
                    text={t('button.last_step')}
                    onClick={() => handleOnBtnClick("last")}
                  />
                }
                <div className='d-flex align-items-center'>
                  <ButtonIconText 
                    className="mx-3" 
                    icon="save" 
                    text={t('button.save_output')}
                    onClick={() => handleConfirmSaveFileName()}
                  />
                  <ButtonText 
                    isKindWhite={false} 
                    text={t('button.'+((stepCurrent + 1 === stepsData.length) ? "give_the_task" : "next_step"))}
                    onClick={() => handleOnBtnClick("next")}
                  />
                </div>
              </div>
              <Para14>{t("topbar.title")}</Para14>
              <div className='d-flex mt-2'>
                <TitleH1 className='mr-2 mb-0'>{taskName}</TitleH1>
                <ButtonIcon icon="edit" onClick={() => setIsShowModalEditTaskName(true)}/>
              </div>
              <StepsBar/>
              <TaskNameChanger 
                modalTitle="title_change_task_name"
                isShowModal={isShowModalEditTaskName} setIsShowModal={setIsShowModalEditTaskName} taskName={taskName}
                handleConfirmTaskNameParam={handleConfirmTaskName}/>
            </>
            : <ButtonText 
              className="mt-3"
              isKindWhite={true} 
              onClick={handleOnClickReset}
              text={t('button.back_to_home_page')}
            />
          }
        </>
      );
    } 
    return (<TitleH1>{t("topbar.title")}</TitleH1>);
  };
  
  return (
    <>  
      {renderContent()}
    </>
  );
};

TopBar.propTypes = {
  onClickTopbarBtn: PropTypes.func, 
  statusValid: PropTypes.bool
};

export default TopBar;