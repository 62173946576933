import React from 'react';
import i18n from '../../main/utils/i18nConfigProvider';

import { LoadingParent, LoadingBox, LoadingImg } from '../styles/styled-components/LoadingFullscreen';
import LoadingAnim from '../../assets/svg/anim/loading.svg';

const LoadingFullscreen = () => (
  <LoadingParent>
    <LoadingBox>
      <LoadingImg src={LoadingAnim} alt={i18n.t('img_alt.loading')}/>
    </LoadingBox>
  </LoadingParent>
);

export default LoadingFullscreen;