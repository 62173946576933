import React from 'react';
import PropTypes from 'prop-types';

import { ButtonTextPinkStyle, ButtonTextWhiteStyle } from '../styles/styled-components/Button';

const ButtonText = ({ 
  className,
  isKindWhite, 
  text,
  onClick, 
  ...props }) => {

  if (isKindWhite) {
    return (
      <ButtonTextWhiteStyle className={className} onClick={onClick} type="button">
        {text}
      </ButtonTextWhiteStyle>
    );
  } 
  
  return (
    <ButtonTextPinkStyle className={className} onClick={onClick} type="button">
      {text}
    </ButtonTextPinkStyle>
  );
};

ButtonText.propTypes = {
  className: PropTypes.string,
  isKindWhite: PropTypes.bool.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func
};

export default ButtonText;  